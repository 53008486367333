import { UserRequestActionType } from '../types/user-request.type';

export const setAllRequests = (payload: any) => ({
  type: UserRequestActionType.SET_ALL_USER_REQUESTS,
  payload: payload,
});

export const setMyRequests = (payload: any) => ({
  type: UserRequestActionType.SET_MY_USER_REQUESTS,
  payload: payload,
});

export const setToProduceRequests = (payload: any) => ({
  type: UserRequestActionType.SET_TO_PRODUCE_USER_REQUESTS,
  payload: payload,
});

export const triggerFilters = (payload: any) => ({
  type: UserRequestActionType.TRIGGER_FILTERS,
  payload: payload,
});
