import { AbstractRepository } from './AbstractRepository';
import { config } from '../../config';
import Request from '../Request';
import { IndicatorFilterInterface } from '../../store/types/indicator.type';
import {
  GET_MINE_REQUESTS_PER_STATUS_URI,
  GET_REQUESTS_PER_STATUS_URI,
  GET_TO_PRODUCE_REQUESTS_PER_STATUS_URI,
} from '../../constants/routes';
import { store } from '../../store';
import { GetOneIndicator } from '../../store/types/one-indicator.type';
import { AxiosResponse } from 'axios';

class IndicatorReportingRepository extends AbstractRepository {
  get(indicatorId: string): Promise<AxiosResponse<GetOneIndicator>> {
    const currentStore = store.getState();

    const url = config.apiUrl + `/api/${currentStore.user.info.contractId}/request/${indicatorId}`;

    return this.client.get(url);
  }

  edit(indicatorId: string, indicator: any): Promise<AxiosResponse<GetOneIndicator>> {
    const currentStore = store.getState();
    const url = config.apiUrl + `/api/${currentStore.user.info.contractId}/request/${indicatorId}`;

    return this.client.put(url, indicator);
  }

  getAllReporting(filters: IndicatorFilterInterface) {
    const currentStore = store.getState();
    const requestUrl = GET_REQUESTS_PER_STATUS_URI.replace('__contract_id__', currentStore.user.info.contractId);
    const url = config.apiUrl + requestUrl;

    return this.client.get(url, { params: this.prepareFilters(filters) });
  }

  getToProductReporting(filters: IndicatorFilterInterface) {
    const currentStore = store.getState();
    const requestUrl = GET_TO_PRODUCE_REQUESTS_PER_STATUS_URI.replace(
      '__contract_id__',
      currentStore.user.info.contractId
    );
    const url = config.apiUrl + requestUrl;

    return this.client.get(url, { params: this.prepareFilters(filters) });
  }

  getMineProductReporting(filters: IndicatorFilterInterface) {
    const currentStore = store.getState();
    const requestUrl = GET_MINE_REQUESTS_PER_STATUS_URI.replace('__contract_id__', currentStore.user.info.contractId);
    const url = config.apiUrl + requestUrl;

    return this.client.get(url, { params: this.prepareFilters(filters) });
  }
}

export default new IndicatorReportingRepository(Request);
