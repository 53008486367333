import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { SzButton, SzModal } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";

export const RouterPrompt = (props: any) => {
    const { when, onOK, onCancel, title } = props;

    const history = useHistory();
    const { t } = useTranslation();

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState("");

    useEffect(() => {
        if (when) {
            history.block((prompt) => {
                setCurrentPath(prompt.pathname);
                setShowPrompt(true);
                return "true";
            });
        } else {
            history.block(() => {});
        }

        return () => {
            history.block(() => {});
        };
    }, [history, when]);

    const handleOK = useCallback(async () => {
        if (onOK) {
            const canRoute = await Promise.resolve(onOK());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
            }
        }
    }, [currentPath, history, onOK]);

    const handleCancel = useCallback(async () => {
        if (onCancel) {
            const canRoute = await Promise.resolve(onCancel());
            if (canRoute) {
                history.block(() => {});
                history.push(currentPath);
            }
        }
        setShowPrompt(false);
    }, [currentPath, history, onCancel]);

    return showPrompt ? (
        <SzModal title={title} show={showPrompt} handleClose={handleCancel} size="lg">
            {t("sheet:form.message_block")}
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleOK();
                }}
            >
                <div className="form-actions text-center mt-4">
                    <SzButton
                        variant="secondary"
                        onClick={() => {
                            handleCancel();
                        }}
                        className="mr-2"
                    >
                        {t("sheet:form.stay")}
                    </SzButton>
                    <SzButton type="submit">{t("sheet:form.leave")}</SzButton>
                </div>
            </form>
        </SzModal>
    ) : null;
};