import {
  Recurrence,
  Deadlines,
  regexYearlyRecurrence,
  regexMonthlyRecurrence,
  regexQuerterlyRecurrence,
  quarterlyMonths,
  regexBiannualRecurrence,
  biannualMonths,
} from '../constants';
import { DateService } from '../services/DateService';
import { INDICATOR_STATUS } from '../store/types/indicator.type';
import { capitalizeFirstLetter } from '../tools';
import { GetOneIndicator } from '../store/types/one-indicator.type';
import React from 'react';
import { SzTypographie } from '@suezenv/react-theme-components';

export interface FormatedIndicator {
  id: string;
  incrementalId: number;
  instance: JSX.Element;
  recurrence: string;
  indicatorCode: JSX.Element;
  indicatorLabel: string;
  status: string;
  statusId: string;
  validationLimitDate: JSX.Element;
}

export const formatRequests = (userRequests: { items: GetOneIndicator[] }, translate: any): FormatedIndicator[] => {
  const formattedRequests: FormatedIndicator[] = [];

  if (userRequests.items) {
    userRequests.items.map((item) => {
      const delayToApprouve = deadlinesToApprouve(
        item.data.validationLimitDate!,
        item.currentStatus.status.id,
        translate
      );

      const request: FormatedIndicator = {
        id: item.id,
        incrementalId: item.incrementalId,
        instance: cellFormatter(
          generateInstanceLabel(item.data.recurrence.id, item.data.validationLimitDate!),
          item.data.recurrence.id,
          translate
        ),
        recurrence: item.data.recurrence.label,
        indicatorCode: cellFormatter(item.data.indicatorCode, item.data.indicatorType.id, translate),
        indicatorLabel: item.data.indicatorName,
        status: translate(`status_user_request.${item.currentStatus.status.id}`),
        statusId: item.currentStatus.status.id,
        validationLimitDate: cellFormatter(
          DateService.formatDate(item.data.validationLimitDate),
          delayToApprouve,
          translate,
          true
        ),
      };

      formattedRequests.push(request);

      return formattedRequests;
    });
  }
  return formattedRequests;
};

export const generateInstanceLabel = (recurrence: string, validationLimitDate: string): string => {
  let instance: string = '';
  switch (recurrence) {
    case Recurrence.RECURRENCE_YEARLY:
      instance = DateService.subscribeDateByMonth(validationLimitDate, 12, 'Y');
      break;
    case Recurrence.RECURRENCE_MONTHLY:
      const formatedDate = DateService.subscribeDateByMonth(validationLimitDate, 1, 'MMMM Y');
      instance = capitalizeFirstLetter(DateService.formatDate(formatedDate, { month: 'long', year: 'numeric' }));
      break;
    case Recurrence.RECURRENCE_BIANNUAL:
      instance = `S${DateService.subscribeDateBySemester(validationLimitDate, 1)}`;
      break;
    case Recurrence.RECURRENCE_QUARTERLY:
      instance = `T${DateService.subscribeDateByMonth(validationLimitDate, 1, 'Q Y')}`;
      break;
    case Recurrence.RECURRENCE_PUNCTUAL:
      instance = DateService.formatDate(validationLimitDate);
      break;
  }

  return instance;
};

export const convertInstance = (instance: any): any => {
  let recurrence,
    startDate,
    endDate: string = '';
  let year: number;
  let instancesArray = instance.split(' ');

  if (null !== instance.match(regexYearlyRecurrence)) {
    // yearly 2020 => rec: yearly, startDate: 01/01/2021, endDate: 31/01/2021
    recurrence = Recurrence.RECURRENCE_YEARLY;
    startDate = DateService.addPeriod(`01/01/${instance}`, 1, 'year');
    endDate = DateService.addPeriod(`12/31/${instance}`, 1, 'year');
  }

  if (null !== instance?.match(regexMonthlyRecurrence)) {
    // monthly Février 2021 => rec: monthly, startDate: 01/03/2021, endDate: 31/03/2021
    recurrence = Recurrence.RECURRENCE_MONTHLY;
    startDate = DateService.dateToString(new Date(`${instancesArray[0]} 01, ${instancesArray[1]}`));
    endDate = DateService.dateToString(new Date(`${instancesArray[0]} 31, ${instancesArray[1]}`));
  }

  if (null !== instance?.match(regexQuerterlyRecurrence)) {
    // quarterly T1 2021 => rec: quarterly, startDate: 01/04/2021, endDate: 31/06/2021
    year = parseInt(instancesArray[1]);
    if ('T4' === instancesArray[0]) {
      year++;
    }
    recurrence = Recurrence.RECURRENCE_QUARTERLY;
    startDate = DateService.subscribeDateByMonth(`${quarterlyMonths[instancesArray[0]]}/01/${year}`, 2);
    endDate = DateService.subscribeDateByMonth(`${quarterlyMonths[instancesArray[0]]}/31/${year}`, 0);
  }

  if (null !== instance?.match(regexBiannualRecurrence)) {
    // semester S1 2021 => rec: biannual, startDate: 01/07/2021, endDate: 31/12/2021
    year = parseInt(instancesArray[1]);
    if ('S2' === instancesArray[0]) {
      year++;
    }
    recurrence = Recurrence.RECURRENCE_BIANNUAL;
    startDate = DateService.subscribeDateByMonth(`${biannualMonths[instancesArray[0]]}/01/${year}`, 5);
    endDate = DateService.subscribeDateByMonth(`${biannualMonths[instancesArray[0]]}/31/${year}`, 0);
  }

  return { recurrence, startDate, endDate };
};

const deadlinesToApprouve = (
  date: string,
  statut: string,
  translate: any
): { format: Deadlines | null; text: string } => {
  // validated request have no delay
  if (statut === INDICATOR_STATUS.VALIDATED) {
    return { format: null, text: '' };
  }
  const now = new Date();
  const testedDate = new Date(date);
  const daysDiff = DateService.daysDiff(testedDate, now);
  const diffrenceDate = DateService.dateDiff(testedDate, now);

  return daysDiff < 0
    ? { format: Deadlines.LATE, text: Deadlines.LATE }
    : daysDiff > 0
    ? {
        format: Deadlines.ON_TIME,
        text: `${diffrenceDate.value} ${translate(diffrenceDate.format)}`,
      }
    : { format: Deadlines.LIMIT, text: Deadlines.LIMIT };
};

const cellFormatter = (value: any, row: any, translate: any, isValueDate: boolean = false) => {
  return (
    <>
      <div className={isValueDate && row.format ? `request-row-${row.format.replace('_', '-')}` : ''}>
        <div style={{ margin: '6px' }}>
          {value}
          <SzTypographie variant="caption" weight="light">
            {isValueDate ? translate(`${row.text}`) : translate(`${row}`)}
          </SzTypographie>
        </div>
      </div>
    </>
  );
};
