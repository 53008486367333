import { AbstractRepository } from './AbstractRepository';
import { config } from '../../config';
import Request from '../Request';
import { store } from '../../store';
import { IndicatorStatus, NewComment } from '../../store/types/indicator.comment.type';
import { AxiosResponse } from 'axios';
import { IndicatorStatusType } from '../../store/types/indicator.type';

class IndicatorStatusRepository extends AbstractRepository {
  comment(indicatorId: string, comment: NewComment) {
    const currentStore = store.getState();
    const url = config.apiUrl + `/api/${currentStore.user.info.contractId}/request/${indicatorId}/comment`;

    return this.client.post(url, comment);
  }

  listStatus(indicatorId: string): Promise<AxiosResponse<IndicatorStatus[]>> {
    const currentStore = store.getState();
    const url = config.apiUrl + `/api/${currentStore.user.info.contractId}/request/${indicatorId}/status`;

    return this.client.get(url);
  }

  updateStatus(indicatorId: string, newStatus: IndicatorStatusType, comment: NewComment) {
    const currentStore = store.getState();
    const url = config.apiUrl + `/api/${currentStore.user.info.contractId}/request/${indicatorId}/status`;

    return this.client.post(url, {
      status: newStatus,
      comment: comment.comment,
    });
  }
}

export default new IndicatorStatusRepository(Request);
