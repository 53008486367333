import React, { ReactNode, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { SzSideMenu, SzAlert, SzIcon, SzModal } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import { AppUrls } from '../../constants';
import Header from '../elements/header';
import Loading from '../elements/loading';
import LoadingPage from '../../pages/loadingPage';
import './navigation.scss';
import { config } from '../../config';
import UserService from '../../services/UserService';
import ToastList from '../elements/toasts/toast-list';

interface INavigationProps {
  children: ReactNode;
  loading: Boolean;
  logged: Boolean;
  isError: boolean;
  user: any;
}

const Navigation: React.FunctionComponent<INavigationProps> = (props) => {
  const { children, user } = props;
  const [state, setState] = useState({ isNavOpen: false, showUrgentRequest: false });
  const { t } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const modalTitle = t('notification_setting');

  const closeMenuHandler = () => {
    setState({ ...state, showUrgentRequest: false });
  };
  const toggleMenuHandler = () => {
    setState({
      ...state,
      showUrgentRequest: !state.showUrgentRequest,
      isNavOpen: state.isNavOpen,
    });
  };

  const menuTop = [
    {
      icon: 'layout-dashboard',
      link: AppUrls.INDICATORS,
      title: t('MENU_INDICATORS'),
    },
  ];

  const SzSideMenuClassName = 'flex-shrink-0' + (!state.isNavOpen ? ' closed' : '');

  return props.logged || props.isError ? (
    <>
      <ToastList />
      <Header
        isError={props.isError}
        handleOnClick={() => setState({ ...state, isNavOpen: !state.isNavOpen })}
        setModalShow={setModalShow}
        user={user}
      />
      <div className="d-flex flex-row flex-fill main-wrapper">
        {!props.isError && (
          <SzSideMenu className={SzSideMenuClassName} isOpen={state.isNavOpen}>
            <ul className="sz-aside__list list-unstyled m-0">
              {menuTop.map((item, key) => (
                <li key={key} className="d-flex align-items-center">
                  <NavLink
                    activeClassName="active"
                    className="sz-aside__item d-flex align-items-center font-weight-bold text-uppercase text-decoration-none py-2"
                    exact={true}
                    to={item.link}
                    isActive={(match, location) => {
                      const pathNameList = location.pathname.split('/');
                      const linkList = item.link.split('/');
                      return linkList[1] === pathNameList[1];
                    }}
                  >
                    <span className={`sz-icon-line ${item.icon}`} />
                    <span className={'sz-aside__item--title'}>{item.title}</span>
                  </NavLink>
                </li>
              ))}
            </ul>

            {UserService.isSuez(user.info) && (
              <ul className="sz-aside__list list-unstyled m-0">
                <li className="d-flex align-items-center">
                  <NavLink
                    className="sz-aside__item d-flex align-items-center font-weight-bold text-uppercase text-decoration-none py-2"
                    to={AppUrls.SHEETS}
                  >
                    <SzIcon icon="business-contract-handshake-sign" variant="line" />
                    <span className={'sz-aside__item--title'}>{t('MENU_SHEET')}</span>
                  </NavLink>
                </li>

                <li className="d-flex align-items-center">
                  <a
                    className="sz-aside__item d-flex align-items-center font-weight-bold text-uppercase text-decoration-none py-2"
                    href={config.userAdminUrl}
                    target="_blank"
                  >
                    <span className={'sz-icon-line cog'} />
                    <span className={'sz-aside__item--title'}>{t('MENU_USER_ADMIN')}</span>
                  </a>
                </li>
              </ul>
            )}
          </SzSideMenu>
        )}
        <main className="flex-grow-1 d-flex flex-column">
          {props.loading && <Loading />}
          <div className="flex-fill position-relative no-scroll h-100">
            <div className="container-fluid overflow-auto h-100">{children}</div>
          </div>
          <SzModal
            className={'modal-centered'}
            title={modalTitle}
            show={modalShow}
            size="xl"
            backdrop={true}
            centered={true}
            handleClose={() => {
              setModalShow(false);
            }}
          >
            todo here
          </SzModal>
        </main>
      </div>
    </>
  ) : (
    <LoadingPage />
  );
};

export default Navigation;
