import { SheetActionType } from '../types/sheet.type';

const initialState = {
  all: [],
};

const SheetReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SheetActionType.SET_ALL_SHEETS:
      return { ...state, all: action.payload };
    default:
      return state;
  }
};

export default SheetReducer;
