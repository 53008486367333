import React, { useEffect, useState } from 'react';
import { SzTab } from '@suezenv/react-theme-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { INDICATOR_REQUEST_TYPE, IndicatorRequestType } from '../../store/types/indicator.type';
import IndicatorFilter from './filter/indicator.filter';
import UserRequestsList from '../../manager/userRequest/userRequests.list';
import { RequestListPages } from '../../constants';
import { connect } from 'react-redux';
import { saveActiveTab } from '../../store/actions';
import { ParamsState } from '../../store/types/params.type';
import './index.scss';

const { SzTabContent } = SzTab;

interface IndicatorsProps extends RouteComponentProps {
  activeTab: INDICATOR_REQUEST_TYPE;
  saveActiveTab: (payload: ParamsState) => void;
}

const Indicators = (props: IndicatorsProps) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<IndicatorRequestType>(props.activeTab ?? INDICATOR_REQUEST_TYPE.TO_PRODUCE);

  useEffect(() => {
    props.saveActiveTab({ activeTab: tab });
  }, [tab]);

  return (
    <div className="container-fluid mt-5">
      <SzTab
        id="indicators"
        activeKey={tab}
        className="indicators-tabs"
        selectTabHandle={(tab: IndicatorRequestType) => setTab(tab)}
      >
        <SzTabContent eventKey={INDICATOR_REQUEST_TYPE.TO_PRODUCE} title={t('INDICATORS_TO_PRODUCE')}>
          <IndicatorFilter type={INDICATOR_REQUEST_TYPE.TO_PRODUCE} />
          <UserRequestsList type={RequestListPages.TO_PRODUCE} />
        </SzTabContent>
        <SzTabContent eventKey={INDICATOR_REQUEST_TYPE.MINE} title={t('MY_INDICATORS')}>
          <IndicatorFilter type={INDICATOR_REQUEST_TYPE.MINE} />
          <UserRequestsList type={RequestListPages.MINE} />
        </SzTabContent>
        <SzTabContent eventKey={INDICATOR_REQUEST_TYPE.ALL} title={t('ALL_INDICATORS')}>
          <IndicatorFilter type={INDICATOR_REQUEST_TYPE.ALL} />
          <UserRequestsList type={RequestListPages.ALL} />
        </SzTabContent>
      </SzTab>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  activeTab: state.params.activeTab,
});

const mapDispatchToProps = {
  saveActiveTab,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Indicators));
