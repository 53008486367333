import { ISelectOption } from '.';

export enum INDICATOR_STATUS {
  DRAFT = 'draft',
  TO_APPROVE = 'to_approve',
  TO_VALIDATE = 'to_validate',
  REFUSED = 'refused',
  VALIDATED = 'validated',
}

export type IndicatorStatusType =
  | INDICATOR_STATUS.DRAFT
  | INDICATOR_STATUS.TO_APPROVE
  | INDICATOR_STATUS.TO_VALIDATE
  | INDICATOR_STATUS.REFUSED
  | INDICATOR_STATUS.VALIDATED;

export enum INDICATOR_REQUEST_TYPE {
  MINE = 'mine',
  ALL = 'all',
  TO_PRODUCE = 'toProduce',
}

export type IndicatorRequestType =
  | INDICATOR_REQUEST_TYPE.MINE
  | INDICATOR_REQUEST_TYPE.ALL
  | INDICATOR_REQUEST_TYPE.TO_PRODUCE;

export type IndicatorFilterInterface = {
  [e: string]: any;
  q: string;
  status?: ISelectOption | null;
  indicator?: ISelectOption | null;
  instance: ISelectOption | null;
  startDate?: any;
  endDate?: any;
};
