import React from 'react';
import { Form, Formik } from 'formik';
import { SzButton, SzInput } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';

export interface SheetFilterListValue {
  search: string;
}

interface Props {
  onSubmit: (values: SheetFilterListValue) => any;
}

const SheetFilterList: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation('sheets');

  const trans = (key: string) => {
    return t(`filter.form.${key}`);
  };

  const initialValue: SheetFilterListValue = {
    search: '',
  };

  return (
    <Formik initialValues={initialValue} onSubmit={onSubmit}>
      {({ handleSubmit, handleChange, values, setFieldValue }) => {
        const { search } = values;

        return (
          <Form onSubmit={handleSubmit}>
            <div className="d-flex align-items-center">
              <div className="form-row col-7 pl-1">
                <div className="col-10">
                  <SzInput
                    handleClear={() => setFieldValue('search', '')}
                    name="search"
                    value={search}
                    onChange={handleChange}
                    placeholder={trans('search')}
                  />
                </div>
                <div className="col-2">
                  <SzButton className="filter-search-submit px-5 pl-4" icon="search" type="submit" />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SheetFilterList;
