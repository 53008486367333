import { Constants } from '../../constants';
import { AppActions, IError } from '../types';

export const updateError = (error: IError): AppActions => {
  return {
    type: Constants.ERROR,
    payload: error,
  };
};

export const updateErrorIntervention = (error: IError): AppActions => {
  return {
    type: Constants.ERROR_INTERVENTION,
    payload: error,
  };
};
