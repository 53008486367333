import React, { useCallback, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { SzIcon } from '@suezenv/react-theme-components';
import './file-uploader.scss';
import {useTranslation} from "react-i18next";

export interface FileUploaderProps {
  onChange: (files: File[]) => void;
  dragAndDropText: string;
  showCurrentFiles?: boolean;
  canDeleteFile?: boolean;
  multiple?: boolean;
  className?: string;
}

const ALLOWED_EXTENSION = ['.doc', '.docx', '.xls', '.xlsx', '.ppt', '.pptx', '.pdf', '.png', '.jpg', '.jpeg'];

const FileUploader: React.FC<FileUploaderProps> = ({
  onChange,
  dragAndDropText,
  canDeleteFile = false,
  showCurrentFiles = true,
  multiple = true,
  className = '',
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const {t} = useTranslation('indicator');
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const deleteFile = useCallback(
    (fileName: string) => {
      const listFiles = files.filter((file) => file.name !== fileName);
      setFiles([...listFiles]);
    },
    [files]
  );

  useEffect(() => {
    onChange(files);
  }, [files]);


  return (
    <div className={`file-uploader ${className}`}>
      <Dropzone
        multiple={multiple}
        accept={ALLOWED_EXTENSION}
        onDropAccepted={(addedFiles) => {
          if (multiple) {
            const allFiles = files.concat(addedFiles);
            onChange(allFiles);
            setFiles([...allFiles]);
          } else {
            onChange([...addedFiles]);
            setFiles([...addedFiles]);
          }
        }}
        onDropRejected={() => setShowErrorMessage(true)}
      >
        {({ getRootProps, getInputProps }) => (
          <section className={`dropzone-wrapper`}>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="dropzone-text">
                  <div>
                      {dragAndDropText}
                      <br/>
                      <small>{t('allowed_extensions')}</small>
                  </div>
                  <div>
                      <SzIcon icon="common-file-text-upload" variant="line" />
                      { showErrorMessage && <div className="alert bg-warning">{t('unallowed_extension')}</div>}
                  </div>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
      {showCurrentFiles && (
        <div className="dropzone-current-files">
          {files.map((file, index) => (
            <div className="current-file-item" key={file.name}>
              {file.name}{' '}
              {canDeleteFile && (
                <SzIcon
                  className="current-file-item-delete"
                  icon="remove"
                  variant="line"
                  onClick={() => deleteFile(file.name)}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUploader;
