import React, { useEffect, Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { store } from './store';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';
import { AuthenticateService } from './services/AuthenticateService';
import NavigationManager from './manager/navigation';
import Logout from './pages/logout';
import ErrorManager from './manager/error';
import { AppUrls, ErrorRoutes } from './constants';
import { Theme } from '@suezenv/react-theme-components';
import Indicators from './pages/Indicators';
import Indicator from './pages/indicator/indicator';
import SheetList from './pages/sheets/sheet.list';
import SheetCreate from './pages/sheets/sheet.create';
import SheetEdit from './pages/sheets/sheet.edit';

interface IAppProps {
  history: History;
}

const AppRouter = ({ history }: IAppProps) => {
  useEffect(() => {
    const pathName = history.location.pathname;
    const token = localStorage.getItem('token-atlas-indicateur');
    if ((!token || token === 'undefined') && pathName !== ErrorRoutes.SSO_ERROR) {
      AuthenticateService.login();
    } else if (pathName !== ErrorRoutes.SSO_ERROR) {
      AuthenticateService.checkToken();
    }
  }, [history]);

  return (
    <Suspense fallback={<></>}>
      <Provider store={store}>
        <BrowserRouter
            getUserConfirmation={() => {
              /* Empty callback to block the default browser prompt */
            }}
        >
          <Theme.Provider brand={'EDTM'}>
            <NavigationManager>
              <Switch>
                <Route exact path={AppUrls.LOGOUT} component={Logout} />
                <Route exact path={AppUrls.HOME} render={() => <Redirect to={AppUrls.INDICATORS} />} />
                <Route exact path={AppUrls.INDICATORS} component={Indicators} />
                <Route exact path={AppUrls.INDICATOR} component={Indicator} />
                <Route exact path={AppUrls.SHEETS} component={SheetList} />
                <Route exact path={AppUrls.CREATE_SHEETS} component={SheetCreate} />
                <Route exact path={AppUrls.EDIT_SHEET} component={SheetEdit} />

                <Route
                  exact
                  path={[ErrorRoutes.NOT_FOUND, ErrorRoutes.ACCESS_DENIED, ErrorRoutes.OUPS, ErrorRoutes.SSO_ERROR]}
                  component={ErrorManager}
                />
                <Route render={() => <Redirect to={AppUrls.HOME} />} />
              </Switch>
            </NavigationManager>
          </Theme.Provider>
        </BrowserRouter>
      </Provider>
    </Suspense>
  );
};

export default AppRouter;
