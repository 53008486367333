import React from 'react';
import { GetOneIndicator } from '../../../store/types/one-indicator.type';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { NewComment } from '../../../store/types/indicator.comment.type';
import { SzButton, SzTextArea } from '@suezenv/react-theme-components';
import * as yup from 'yup';
import IndicatorStatusRepository from '../../../services/repository/IndicatorStatusRepository';

interface Props {
  indicator: GetOneIndicator;
}

const IndicatorNewComment: React.FC<Props> = ({ indicator }) => {
  const { t } = useTranslation('indicator');

  const trans = (key: string): string => {
    return t('page.comments.form.' + key);
  };

  const comment: NewComment = {
    comment: '',
  };

  const schema = yup.object().shape<NewComment>({
    comment: yup.string().required(),
  });

  const onSubmitForm = (comment: NewComment) => {
    IndicatorStatusRepository.comment(indicator.id, comment).then(() => {
      window.location.reload();
    });
  };

  return (
    <Formik initialValues={comment} onSubmit={onSubmitForm} validationSchema={schema} validateOnMount>
      {(formikProps) => {
        const {
          handleSubmit,
          handleChange,
          values: { comment },
          isValid,
        } = formikProps;

        return (
          <Form onSubmit={handleSubmit} className="py-3">
            <SzTextArea
              name="comment"
              value={comment}
              onChange={handleChange}
              placeholder={trans('comment_placeholder')}
            />
            <div className="clearfix">
              <SzButton className="float-right" type="submit" isDisabled={!isValid}>
                {trans('submit_button')}
              </SzButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default IndicatorNewComment;
