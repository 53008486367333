import React from 'react';
import './index.scss';
import { SzTypographie } from '@suezenv/react-theme-components';
import classnames from 'classnames';
import { DateService } from '../../../services/DateService';

export const SzTimeLine = (props: any) => {
  const { items } = props;
  return (
    <>
      <ul className="timeline-horizontal flex-fill d-flex align-items-stretch">
        {items.map((item: any, index: any) => {
          const itemClassName = classnames(
            'timeline-item',
            items.length === index + 1 ? '' : 'flex-fill',
            item.className
          );

          const itemCreatedAt = DateService.formatDate(item.createdAt);

          return (
            <li key={index} className={itemClassName}>
              <div className="timeline-badge" title={!item.showInTimeline ? `${itemCreatedAt} ${item.text}` : ''}></div>
              {item.showInTimeline && (
                <div className="timeline-text">
                  <SzTypographie className="mb-1" variant="text">
                    {itemCreatedAt}
                  </SzTypographie>
                  <SzTypographie variant="text">{item.text}</SzTypographie>
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};
