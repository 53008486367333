import { IndicatorStatusType } from './indicator.type';

export enum INDICATOR_TYPE {
  REPORT = 'file',
  VALUE = 'value',
}

export type IndicatorTypeType = INDICATOR_TYPE.REPORT | INDICATOR_TYPE.VALUE;

export enum ATTACHMENT_TYPE {
  REPORT = 'report',
  REPORT_ATTACHMENT = 'report_attachment',
  ATTACHMENT = 'attachment',
}

interface Status {
  id: IndicatorStatusType;
  label: string;
}

export interface ApplicationUser {
  title: string;
  phone: string;
  firstName: string;
  lastName: string;
  email: string;
  gid: string;
  source: string;
  metaData: {
    [key: string]: string;
  };
}

interface CurrentStatus {
  id: string;
  status: Status;
  application: string;
  comment: string;
  applicationUser: ApplicationUser;
  assignedUser?: ApplicationUser;
  commentRole: string[];
  createdAt: string;
  updatedAt: string;
}

export interface ReportFile {
  id: string;
  image?: string;
  mimeType: string;
  originalFileName: string;
  size?: number;
}

export interface SheetUser {
  id: string;
  firstName: string;
  lastName: string;
  company?: string;
  phoneNumber?: string;
  email?: string;
}

export interface IndicatorData {
  indicatorName: string;
  indicatorCode: string;
  indicatorType: {
    id: IndicatorTypeType;
    label: string;
  };
  validationDate?: string;
  validationLimitDate: string;
  toProducedLimitDate?: string;
  toApproveLimitDate?: string;
  toValidateLimitDate?: string;
  gedUrl: string;
  recurrence: {
    id: string;
    label: string;
  };
  objective: string;
  description: string;
  theme: string;
  value: string;
  reportFile: ReportFile;
  suppliers: SheetUser[];
  approvers: SheetUser[];
  validators: SheetUser[];
}

export interface Context {
  id: string;
  label: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

interface Address {
  id: string;
  address: string;
  number: string;
  street: string;
  postalCode: string;
  city: string;
  country: string;
  latitude: string;
  longitude: string;
}

export interface AttachmentFile {
  id: string;
  image?: boolean;
  originalFileName: string;
  size: number;
  mimeType: string;
  createdAt: string;
  updatedAt: string;
}

export interface Attachment {
  request?: any;
  file: AttachmentFile;
  type: ATTACHMENT_TYPE;
  createdAt: string;
  updatedAt: string;
}

export interface GetOneIndicator {
  id: string;
  nextPossibleStatuses: Status[];
  currentStatus: CurrentStatus;
  incrementalId: number;
  functionalId: string;
  data: IndicatorData;
  ownerApplication: string;
  creatorApplication: string;
  requestType: string;
  subject: string;
  comment: string;
  languageCode: string;
  applicationUser: ApplicationUser;
  assignedUser?: ApplicationUser;
  context: Context[];
  address: Address;
  attachments: Attachment[];
  importedUser: ApplicationUser[];
  commentRole: string[];
  createdAt: string;
  updatedAt: string;
  deletedAt?: string | null;
}
