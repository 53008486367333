import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IndicatorReportingType } from '../../store/types/indicator-reporting.type';
import IndicatorReportingRepository from '../repository/IndicatorReportingRepository';
import {
  INDICATOR_REQUEST_TYPE,
  IndicatorFilterInterface,
  IndicatorRequestType,
} from '../../store/types/indicator.type';
import { initialStateFilter } from '../../store/reducers/user_requests_reducer';

/**
 * @param indicatorType
 * @param filters
 */
export const useIndicatorReporting = (
  indicatorType: IndicatorRequestType,
  filters: IndicatorFilterInterface = initialStateFilter
): [IndicatorReportingType | undefined, Dispatch<SetStateAction<IndicatorReportingType | undefined>>] => {
  const [indicatorsReporting, setIndicatorsReporting] = useState<IndicatorReportingType>();

  useEffect(() => {
    switch (indicatorType) {
      case INDICATOR_REQUEST_TYPE.ALL:
        IndicatorReportingRepository.getAllReporting(filters).then((response) => {
          setIndicatorsReporting(response.data);
        });
        break;
      case INDICATOR_REQUEST_TYPE.MINE:
        IndicatorReportingRepository.getMineProductReporting(filters).then((response) => {
          setIndicatorsReporting(response.data);
        });
        break;
      case INDICATOR_REQUEST_TYPE.TO_PRODUCE:
        IndicatorReportingRepository.getToProductReporting(filters).then((response) => {
          setIndicatorsReporting(response.data);
        });
        break;
    }
  }, [filters]);

  return [indicatorsReporting, setIndicatorsReporting];
};
