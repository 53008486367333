import React, { useEffect, useState } from 'react';
import { SzTextArea, SzInput, SzButton, SzModal } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import { INDICATOR_STATUS, IndicatorStatusType } from '../../../../store/types/indicator.type';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import FileUploader from '../../../../components/elements/file-uploader/file-uploader';

interface Props {
  onClose?: () => void;
  onValidate: (status: IndicatorStatusType, values: any, bag: any) => void;
  show: boolean;
}

export interface ValueToApproveType {
  value: string;
  files: any[];
  comment: string;
}

const ModalValueToApproveStatus: React.FC<Props> = (props) => {
  const [show, setShow] = useState(props.show);
  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const { t } = useTranslation('indicator');
  const trans = (key: string): string => {
    return t('page.modal.value_to_approve_status.' + key);
  };

  const initialValue: ValueToApproveType = {
    value: '',
    files: [],
    comment: '',
  };

  const schema = yup.object().shape<Partial<ValueToApproveType>>({
    value: yup.string().required(),
  });

  return (
    <SzModal
      className="modal-value-to-approve-status"
      handleClose={() => props.onClose?.()}
      hideCloseBtn
      centered
      title={trans('title')}
      size="xl"
      show={show}
    >
      <Formik
        initialValues={initialValue}
        onSubmit={(values, bag) => props.onValidate(INDICATOR_STATUS.TO_APPROVE, values, bag)}
        validateOnMount
        validationSchema={schema}
      >
        {({ values, handleChange, handleSubmit, isValid, isSubmitting, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div>
                <SzInput
                  name="value"
                  value={values.value}
                  onChange={handleChange}
                  placeholder={trans('form.value_label')}
                  className="to-approve-modal-value"
                />

                <div className="mt-4">
                  <FileUploader
                    canDeleteFile
                    showCurrentFiles
                    dragAndDropText={trans('drag_drop_text')}
                    onChange={(files) => setFieldValue('files', files)}
                    className="to-approve-modal-file"
                  />
                </div>

                <SzTextArea
                  className="mt-4 to-approve-modal-comment"
                  name="comment"
                  value={values.comment}
                  onChange={handleChange}
                  placeholder={trans('form.comment_label')}
                />
              </div>

              <div className="d-flex justify-content-around">
                <SzButton
                  variant="secondary"
                  onClick={() => {
                    setShow(false);
                    props.onClose?.();
                  }}
                >
                  {trans('button_cancel')}
                </SzButton>
                <SzButton isDisabled={!isValid || isSubmitting} type="submit" variant="primary">
                  {trans('button_validate')}
                </SzButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SzModal>
  );
};

export default ModalValueToApproveStatus;
