import { IndicatorStatusType } from '../../../store/types/indicator.type';
import { IndicatorReportingType } from '../../../store/types/indicator-reporting.type';

export const getReportingCountForType = (type: IndicatorStatusType, reporting?: IndicatorReportingType): number => {
  if (!reporting || reporting.statusReport.length === 0) {
    return 0;
  }

  const status = reporting.statusReport
    ? reporting.statusReport[0].statuses.find((status) => status.id === type)
    : null;

  return status ? status.count : 0;
};
