import React, { useEffect, useState } from 'react';
import { SzInput, SzButton, SzBox, SzSelect, SzDateRangePicker } from '@suezenv/react-theme-components';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import ReportingList from '../reporting/reporting.list';
import { IndicatorFilterInterface, IndicatorRequestType } from '../../../store/types/indicator.type';
import classnames from 'classnames';
import { connect } from 'react-redux';
import SelectedFilter from './selected.filter';
import filterListModel from '../../../models/filterListModels/filter.list.model';
import { getIndicators, getInstances, getStatusOptions } from './filter.options';
import { convertInstance } from '../../../helper/format-data.helper';
import { DateService } from '../../../services/DateService';
import { triggerFilters } from '../../../store/actions/userRequests';
import './filter.scss';

interface Props {
  type: IndicatorRequestType;
  sheets: any;
  storedFilter: IndicatorFilterInterface;
  triggerFiltersStoreAction: (payload: any) => void;
}

const initialValue: IndicatorFilterInterface = {
  q: '',
  status: null,
  indicator: null,
  instance: null,
  startDate: null,
  endDate: null,
};

const selectedFiltersConf: string[] = ['status', 'indicator', 'instance'];

const IndicatorFilter: React.FC<Props> = ({ type, sheets, storedFilter, triggerFiltersStoreAction }) => {
  const { t } = useTranslation('indicator');
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [showClearButton, setShowClearButton] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>([null, null]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState();
  const [currentFilters, setCurrentFilters] = useState<IndicatorFilterInterface>(storedFilter ?? initialValue);
  const [finalFilters, setFinalFilters] = useState<IndicatorFilterInterface>(storedFilter ?? initialValue);
  const updateFilters = (filters: any) => {
    setCurrentFilters({ ...filters });
    setFinalFilters({ ...filters });
  };

  const deleteSelectedFilterHandler = (propName: string, value: string): void => {
    let nextState: any = {};
    let updateFilter: any = {};
    // Delete rangeDate filter
    if (propName === 'rangeDate') {
      updateFilter = { ...currentFilters, startDate: null, endDate: null };
      setDateRange([null, null]);
    } else {
      if (Array.isArray(currentFilters[propName])) {
        nextState[propName] = currentFilters[propName].filter(function (filter: any) {
          return filter.value !== value;
        });
      } else {
        nextState[propName] = null;
      }

      updateFilter = { ...currentFilters, ...nextState };
    }

    updateFilters(updateFilter);
  };

  const clearFilterHandler = (): void => {
    updateFilters(initialValue);
  };

  const resetSearchQuery = (): void => {
    setSearchQuery('');
    updateFilters({ ...currentFilters, q: '' });
  };

  const refreshSelectedFilters = (refreshSelectedFilters = null): void => {
    let elements: any = [];

    const selectedfilters = refreshSelectedFilters ?? currentFilters;
    Object.entries(selectedfilters).map((filterCategory: any) => {
      const filterName = filterCategory[0];
      const filterCategoryValues = filterCategory[1];
      if (null === filterCategoryValues || !selectedFiltersConf.includes(filterName)) {
        return null;
      }

      if (!Array.isArray(filterCategoryValues)) {
        // Single value
        const item = (
          <SelectedFilter
            key={filterName}
            name={filterName}
            deleteHandler={() => deleteSelectedFilterHandler(filterName, filterCategoryValues['value'])}
            label={filterCategoryValues['label']}
          />
        );
        elements.push(item);
        return item;
      } else {
        // Multi values
        if (!filterCategoryValues) {
          return null;
        }

        Object.entries(filterCategoryValues).map((filterCategoryValue: any) => {
          const filter = filterCategoryValue[1];
          const item = (
            <SelectedFilter
              key={filter.value}
              name={filter.value}
              deleteHandler={() => deleteSelectedFilterHandler(filterName, filter.value)}
              label={filter.label}
            />
          );
          elements.push(item);
          return item;
        });
      }

      return null;
    });

    // filter range date
    if (selectedfilters.startDate && selectedfilters.endDate) {
      const labelRange = t('filter.range', {
        startDate: selectedfilters.startDate,
        endDate: selectedfilters.endDate,
      });
      elements.push(
        <SelectedFilter
          key="rangeDate"
          name="rangeDate"
          deleteHandler={deleteSelectedFilterHandler}
          label={labelRange}
        />
      );
    }
    if (selectedfilters.q) {
      elements.push(
        <SelectedFilter
          key="querySearch"
          name="querySearch"
          deleteHandler={resetSearchQuery}
          label={selectedfilters.q}
        />
      );
    }
    if (elements.length > 0) {
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
    }
    setSelectedFilter(elements);
  };

  const triggerFilters = (filters: any = null): void => {
    let selectedFilter: any = filters ? { ...filters } : { ...currentFilters };

    if (null !== currentFilters.instance) {
      const { recurrence, startDate, endDate } = convertInstance(currentFilters.instance.value);
      selectedFilter.recurrence = recurrence;
      selectedFilter.startDate = startDate;
      selectedFilter.endDate = endDate;
    }

    if (null !== dateRange[0] && null !== dateRange[1]) {
      selectedFilter.startDate = DateService.formatDateYMD(dateRange[0]);
      selectedFilter.endDate = DateService.formatDateYMD(dateRange[1]);
    }

    refreshSelectedFilters(selectedFilter);
  };

  const handleSubmit = (e: { q: string }): void => {
    const { q } = e;
    updateFilters({ ...currentFilters, q: !q ? searchQuery : q });
  };

  const handleChange = (option: any, name: any): void => {
    if (name === 'q') {
      setSearchQuery(option.target.value);
    } else {
      setCurrentFilters({ ...currentFilters, [name]: option });
    }
  };

  const handleOnClickFilter = (e: any) => {
    handleSubmit(e);
    if (showFilters) {
      setShowFilters(!showFilters);
    }
  };

  const filterClassesControl = classnames(
    'col-12 mb-4 position-absolute box-shadow ptn-5 z-request-filter flex-fill align-items-stretch',
    {
      'd-none': !showFilters,
    }
  );
  const sendFiltersToRequest = () => {
    let selectedFilter: any = { ...finalFilters };
    if (null !== finalFilters.instance) {
      const { recurrence, startDate, endDate } = convertInstance(finalFilters.instance.value);
      selectedFilter.recurrence = recurrence;
      selectedFilter.startDate = startDate;
      selectedFilter.endDate = endDate;
    }

    if (null !== dateRange[0] && null !== dateRange[1]) {
      selectedFilter.startDate = DateService.formatDateYMD(dateRange[0]);
      selectedFilter.endDate = DateService.formatDateYMD(dateRange[1]);
    }

    triggerFilters({ ...selectedFilter });
    triggerFiltersStoreAction({ ...selectedFilter });
  };

  // Trigger any change filter
  useEffect(() => {
    triggerFilters();
  }, [currentFilters]);

  // Send Request to Api with final filter
  useEffect(() => {
    sendFiltersToRequest();
  }, [finalFilters]);

  // Update filters state from store
  useEffect(() => {
    triggerFilters({ ...storedFilter });
    setCurrentFilters({ ...storedFilter });
  }, [storedFilter]);

  return (
    <Formik initialValues={initialValue} onSubmit={handleSubmit}>
      {(formikProps) => {
        const { handleSubmit } = formikProps;
        return (
          <Form onSubmit={handleSubmit}>
            <div className="d-flex align-items-center">
              <div className="form-row col-6 pl-1">
                <div className="col-9">
                  <SzInput
                    name="q"
                    value={searchQuery}
                    onChange={(e: any) => handleChange(e, 'q')}
                    placeholder={t('filter.search')}
                  />
                </div>
                <div className="col-1">
                  <SzButton className="filter-search-submit px-5 pl-4" icon="search" type="submit" />
                </div>
                <div className="col-1 position-relative">
                  <SzButton
                    className="request-filter mx-2 px-5 py-4 z-base"
                    icon="filter-1"
                    variant="secondary"
                    onClick={() => setShowFilters(!showFilters)}
                  />
                </div>
                { showClearButton &&
                  <div className="col-1 position-relative">
                    <SzButton
                      className="mx-2 px-5 py-4 z-base"
                      icon="remove"
                      variant="secondary"
                      onClick={() => clearFilterHandler()}
                    />
                  </div>
                }
              </div>
              <div className="col-6 mb-3">
                <ReportingList type={type} />
              </div>
            </div>
            <div className="col-sm-11 mb-5 pl-1">
              <SzBox className="filter-tag-box" tag="span">
                {selectedFilter}
              </SzBox>
              {showFilters && (
                <SzBox className={filterClassesControl} tag="div">
                  <div className="float-left w-20 pr-2">
                    <SzSelect
                      id="select-status"
                      className="mb-3 border-secondary"
                      name={filterListModel.status.name}
                      placeholder={t('filter.status')}
                      onChange={(e: any) => handleChange(e, 'status')}
                      options={getStatusOptions(t)}
                      value={currentFilters.status}
                      isClearable={false}
                    />
                  </div>
                  <div className="float-left w-20 pr-2">
                    <SzSelect
                      className="mb-3 border-secondary"
                      name={filterListModel.indicator.name}
                      placeholder={t('filter.indicator')}
                      onChange={(e: any) => handleChange(e, 'indicator')}
                      options={getIndicators(sheets, t)}
                      value={currentFilters.indicator}
                    />
                  </div>
                  <div className="float-left w-30 pr-2 request-range-filter">
                    <SzDateRangePicker
                      selectRange
                      onChange={(dateRange: any) => setDateRange(dateRange)}
                      value={dateRange}
                      toggle
                    />
                  </div>
                  <div className="float-left w-20 pr-2">
                    <SzSelect
                      className="mb-3 border-secondary"
                      name={filterListModel.instance.name}
                      placeholder={t('filter.instance')}
                      onChange={(e: any) => handleChange(e, 'instance')}
                      options={getInstances()}
                      value={currentFilters.instance}
                      isClearable
                    />
                  </div>
                  <div className="float-right w-15 pr-2">
                    <SzButton
                      onClick={(e: any) => {
                        handleOnClickFilter(e);
                      }}
                    >
                      {t('filter.button')}
                    </SzButton>
                  </div>
                </SzBox>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: any) => ({
  sheets: state.sheet.all.items,
  storedFilter: state.userRequests.filter,
});

const mapDispatchToProps = {
  triggerFiltersStoreAction: triggerFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorFilter);
