import React from 'react';
import { AttachmentFile, GetOneIndicator } from '../../../store/types/one-indicator.type';
import { FileDownloader } from '../../../services/FileDownloader';

interface Props {
  indicator: GetOneIndicator;
  file: AttachmentFile;
}

const FileItem: React.FC<Props> = ({ file, indicator }) => {
  return (
    <a
      href="#"
      className={'file-download-item'}
      type="button"
      onClick={() => FileDownloader.downloadFile(indicator.id, file)}
    >
      {file.originalFileName}
    </a>
  );
};

export default FileItem;
