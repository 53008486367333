export enum TOAST_ACTIONS {
  SHOW = 'TOAST_SHOW',
  HIDE = 'TOAST_HIDE',
}

export interface ToastItem {
  id: string;
  body: any;
  header: any;
  delay?: number;
  show?: boolean;
}

export const showToast = (payload: ToastItem) => ({
  type: TOAST_ACTIONS.SHOW,
  payload: payload,
});

export const hideToast = (toastId: string) => ({
  type: TOAST_ACTIONS.HIDE,
  payload: toastId,
});
