import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setRequestData } from './user-request-data.helper';
import { UserRequestType } from '../store/types/user-request.type';
import { FormatedIndicator } from './format-data.helper';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { SzIcon } from '@suezenv/react-theme-components';
import { store } from '../store';

interface IColumns extends ColumnDescription<FormatedIndicator> {
  headerEvents?: {
    onClick: (e: any, column: any) => boolean;
  };
}

const useTableHelper = (
  type: UserRequestType,
  userRequestsData: any,
  sort: {
    direction: string;
    field: string;
  }
): [IColumns[], { direction: string; field: string }] => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState<ColumnDescription<FormatedIndicator>[]>([]);

  useEffect(() => {
    setColumns(initColumns());
  }, []);

  const { filter } = store.getState().userRequests;
  const sortKeyValues: any = {
    status: 'status',
    indicatorCode: 'indicatorCode',
    indicatorLabel: 'indicatorName',
    instance: 'validationLimitDate',
    validationLimitDate: 'validationLimitDate',
  };

  function sortByColumn(e: any, column: any) {
    const { direction } = sort;
    const sortDirection = direction && direction === 'desc' ? 'asc' : 'desc';
    const { filter } = store.getState().userRequests;

    sort = {
      direction: sortDirection,
      field: sortKeyValues[column.dataField],
    };

    const classSortDirection = sortDirection === 'desc' ? 'sz-icon-line arrow-down-1' : 'sz-icon-line arrow-up-1';

    setColumns(initColumns(column.dataField, classSortDirection));
    const pagination = userRequestsData[type].pagination ? userRequestsData[type].pagination : {};
    const currentPage = isNaN(parseInt(pagination['x-pagination-current-page']))
      ? 1
      : parseInt(pagination['x-pagination-current-page']);

    setRequestData(type, currentPage, filter, sortKeyValues[column.dataField], sortDirection);

    return true;
  }

  function dummyColumn(e: any, column: any) {
    return true;
  }

  const columnJson = (
    classes: string,
    dataField: string,
    text: string,
    sortDataFiled: any,
    sortDirection: any
  ): IColumns => {
    let config = {
      classes: classes,
      dataField: dataField,
      text: t(text),
      headerClasses: sortDataFiled === dataField ? sortDirection : 'sz-icon-line move-expand-vertical',
      headerEvents: {
        onClick: sortByColumn,
      }
    }
    if (sortDataFiled === false) {
      config.headerClasses = 'sz-icon-line';
      config.headerEvents.onClick = dummyColumn;
    }

    return config;
  };

  const initColumns = (dataField: string = '', sortDirection: string = '') => {
    const columns: IColumns[] = [
      columnJson('status', 'status', 'status', dataField, sortDirection),
      columnJson('indicatorCode', 'indicatorCode', 'indicatorCode', dataField, sortDirection),
      columnJson('indicatorLabel', 'indicatorLabel', 'indicatorLabel', dataField, sortDirection),
      columnJson('instance', 'instance', 'instance', dataField, sortDirection),
      columnJson('validationLimitDate', 'validationLimitDate', 'validationLimitDate', dataField, sortDirection),
      {
        ...columnJson('action', 'action', 'action', false, ''),
        isDummyField: true,
        formatter: (value, indicator) => (
          <Link to={`/indicateurs/${indicator.id}`}>
            <SzIcon icon={'navigation-menu-4'} variant={'line'}>
              {indicator.id}
            </SzIcon>
          </Link>
        ),
      },
    ];
    return columns;
  };

  return [columns, sort];
};

export default useTableHelper;
