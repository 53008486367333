import { Constants } from '../../constants';
import { ErrorsActionTypes, IError } from '../types';

const initialState: IError = {
  isError: false,
  message: '',
  status: null,
};

const errorReducers = (state = initialState, action: ErrorsActionTypes): IError => {
  switch (action.type) {
    case Constants.ERROR:
      return { isError: action.payload.isError, message: action.payload.message, status: action.payload.status };
    default:
      return state;
  }
};

export default errorReducers;
