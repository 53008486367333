import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SzButton } from '@suezenv/react-theme-components';
import classNames from 'classnames';
import { ATTACHMENT_TYPE, GetOneIndicator } from '../../../store/types/one-indicator.type';
import { FileDownloader } from '../../../services/FileDownloader';
import FileItem from '../../../components/elements/file/file-item';
import IndicatorUser from './indicator.user';
import { getValueOrDefaultIfEmpty } from '../../../services/Utils/string.utils';

interface Props {
  indicator: GetOneIndicator;
}

const IndicatorDetail: React.FC<Props> = ({ indicator }) => {
  const [showDetail, setShowDetail] = useState<boolean>(false);

  const { t } = useTranslation('indicator');

  const trans = (key: string): string => {
    return t('page.' + key);
  };

  const classes = classNames('indicator-detail-content mt-3 px-1', {
    'd-none': !showDetail,
  });

  const toggleDetail = useCallback(() => {
    setShowDetail(!showDetail);
  }, [showDetail]);

  const { data } = indicator;

  const attachmentFiles = FileDownloader.getFilesByType(indicator, ATTACHMENT_TYPE.ATTACHMENT);
  const attachmentReportFiles = FileDownloader.getFilesByType(indicator, ATTACHMENT_TYPE.REPORT_ATTACHMENT);

  return (
    <div className="infobox">
      <div className="d-flex justify-content-between">
        {data.gedUrl ? (
          <SzButton
            onClick={() => window.open(data.gedUrl, '_blank')}
            variant="secondary"
            type="button"
            icon="arrow-rectangle-right"
            alignIcon="right"
          >
            {trans('go_to_sheet')}
          </SzButton>
        ) : (
          <div></div>
        )}

        <SzButton
          variant="secondary"
          type="button"
          icon={showDetail ? 'subtract-square' : 'add-square'}
          alignIcon="right"
          onClick={toggleDetail}
        >
          {showDetail ? trans('hide_detail') : trans('show_detail')}
        </SzButton>
      </div>

      <div className={classes}>
        <h2 className="sub-title mb-5">{trans('information_title')}</h2>
        <div className="bg-gray py-3 row no-gutters justify-content-between">
          <div className="col value-presentation py-2">
            <span className="label">{trans('theme')}</span>
            <span className="value">{getValueOrDefaultIfEmpty(data.theme)}</span>
          </div>
          <div className="col value-presentation py-2">
            <span className="label">{trans('objective')}</span>
            <span className="value">{getValueOrDefaultIfEmpty(data.objective)}</span>
          </div>
          <div className="col value-presentation py-2">
            <span className="label">{trans('description')}</span>
            <span className="value">{getValueOrDefaultIfEmpty(data.description)}</span>
          </div>
        </div>

        <div className="indicator-list-file my-5">
          <h2 className="sub-title mb-4">{trans('annexe')}</h2>

          <div className="bg-gray py-3 row no-gutters justify-content-start">
            <div className="col-4 value-presentation inversed py-2">
              <span className="label">{trans('annexe_list_attachment_report')}</span>

              <div className="py-2">
                {attachmentReportFiles.length === 0 && <p className="bg-gray">{getValueOrDefaultIfEmpty(null)}</p>}
                {attachmentReportFiles.map((attachment) => (
                  <div className="mb-1" key={attachment.file.id}>
                    <FileItem indicator={indicator} file={attachment.file} />
                  </div>
                ))}
              </div>
            </div>
            <div className="col-4 value-presentation inversed py-2">
              <span className="label">{trans('annexe_list_attachment')}</span>

              <div className="py-2">
                {attachmentFiles.length === 0 && <p className="bg-gray">{getValueOrDefaultIfEmpty(null)}</p>}
                {attachmentFiles.map((attachment) => (
                  <div className="mb-1" key={attachment.file.id}>
                    <FileItem indicator={indicator} file={attachment.file} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="indicator-list-user">
          <h2 className="sub-title my-3">{trans('user_supplier_title')}</h2>
          {data.suppliers.map((user) => (
            <IndicatorUser user={user} key={user.id} />
          ))}

          <h2 className="sub-title my-3">{trans('user_approbator_title')}</h2>
          {data.approvers.map((user) => (
            <IndicatorUser user={user} key={user.id} />
          ))}

          <h2 className="sub-title my-3">{trans('user_validator_title')}</h2>
          {data.validators.map((user) => (
            <IndicatorUser user={user} key={user.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default IndicatorDetail;
