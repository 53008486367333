import React, { useState } from 'react';
import { SzIcon, SzTypographie } from '@suezenv/react-theme-components';
import { DateService } from '../../../../services/DateService';
import classNames from 'classnames';

interface Props {
  item: any;
  index: number;
}

const TimelineHistoryItem: React.FC<Props> = ({ item, index }) => {
  const itemClassName = classNames('d-flex timeline-item', item.className);
  const { createdAt, detail, rawComment } = item;
  const [visibleComments, setVisibleComments]: any = useState([]);
  const toggleCommentVisibility = (index: any) => {
    let updatedList = [...visibleComments];

    if (visibleComments.includes(index)) {
      // if the index is already in the visible list, remove it.
      updatedList = visibleComments.filter((commentIndex: any) => {
        return commentIndex !== index;
      });
    } else {
      // otherwise, add it.
      updatedList.push(index);
    }

    // persist
    setVisibleComments(updatedList);
  };

  if (!detail) {
    return null;
  }

  let icon = null;
  let commentDetail = null;

  // comment need specific rendering: they can be hidden / shown
  if (rawComment) {
    const iconVariant = visibleComments.includes(index) ? 'view-off' : 'view-1';
    icon = (
      <SzIcon
        className="ml-2 toggle"
        icon={iconVariant}
        variant="bold"
        onClick={() => toggleCommentVisibility(index)}
      />
    );
    commentDetail = visibleComments.includes(index) ? <div className="pl-3 comment">{rawComment}</div> : null;
  }

  return (
    <>
      <div key={index} className={itemClassName}>
        <div className="status-sticker timeline-badge" />
        <SzTypographie variant="text">
          {DateService.formatDate(createdAt, {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </SzTypographie>
        <SzTypographie className="ml-3" variant="text">
          {detail}
          {icon}
        </SzTypographie>
      </div>
      {commentDetail}
    </>
  );
};

export default TimelineHistoryItem;
