import React, { useMemo, useState } from 'react';
import { SheetFilter, useGetSheets } from '../../services/hooks/sheet.hook';
import { useTranslation } from 'react-i18next';
import { getColumns } from './misc/columns';
import { SzButton, SzIcon, SzPagination, SzTable } from '@suezenv/react-theme-components';
import './sheet.list.scss';
import SheetFilterList from './component/sheet.filter-list';
import { Link } from 'react-router-dom';
import { AppUrls } from '../../constants';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { GetSheet } from '../../store/types/sheet.type';
import SheetRepository from '../../services/repository/SheetRepository';

const ITEM_PER_PAGE = 20;

const SheetList: React.FC = () => {
  const [filter, setFilter] = useState<SheetFilter>({
    currentPage: 1,
    itemsPerPage: ITEM_PER_PAGE,
  });

  const [sheets, , pagination] = useGetSheets(filter);
  const { t } = useTranslation('sheets');

  const deleteSheet = (sheetId: string) => {
    if (window.confirm('Voulez vous vraiment supprimer cette campagne ?')) {
      SheetRepository.delete(sheetId).then(() => {
        window.location.reload();
      });
    }
  };

  const cols = useMemo(() => {
    const columns: ColumnDescription<GetSheet>[] = [
      ...getColumns(t),
      {
        headerClasses: 'px-5',
        classes: 'px-5',
        headerAlign: 'right',
        align: 'right',
        dataField: 'actions',
        isDummyField: true,
        text: t('actions'),
        formatter: (_v, sheet) => (
          <>
            <Link to={`/campagnes/${sheet.id}`}>
              <SzIcon icon="pencil-write" variant="line" />
            </Link>
            <SzIcon icon={'remove-square'} variant={'line'} onClick={() => deleteSheet(sheet.id)} />
          </>
        ),
      },
    ];

    return columns;
  }, []);

  if (!sheets) {
    return null;
  }

  return (
    <div className="my-2 col list-table container">
      <div className="d-flex justify-content-between my-3">
        <div className="w-75">
          <SheetFilterList onSubmit={(values) => setFilter({ ...filter, search: values.search })} />
        </div>
        <div>
          <Link to={AppUrls.CREATE_SHEETS}>
            <SzButton>{t('new_sheet')}</SzButton>
          </Link>
        </div>
      </div>
      <SzTable className="sheet-list-table" columns={cols} data={sheets} keyField="id" />
      <div className="row">
        <div className="col offset-5">
          <SzPagination
            totalItemsCount={Number(pagination['x-pagination-total-count'])}
            activePage={Number(pagination['x-pagination-current-page'])!}
            onChange={(page) =>
              setFilter({
                ...filter,
                currentPage: Number(page),
              })
            }
            itemsCountPerPage={Number(pagination['x-pagination-per-page']) || 20}
            pageRangeDisplayed={5}
          />
        </div>
      </div>
    </div>
  );
};

export default SheetList;
