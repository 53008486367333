import { ParamsState, PARAMS_ACTIONS } from '../types/params.type';

export const saveActiveTab = (payload: ParamsState) => ({
  type: PARAMS_ACTIONS.SAVE_ACTIVE_TAB,
  payload,
});

export const saveActivePage = (payload: ParamsState) => ({
  type: PARAMS_ACTIONS.SAVE_ACTIVE_PAGE,
  payload,
});
