import { AbstractRepository } from './AbstractRepository';
import { config } from '../../config';
import Request from '../Request';
import { store } from '../../store';
import { ATTACHMENT_TYPE } from '../../store/types/one-indicator.type';

class IndicatorFileRepository extends AbstractRepository {
  download(indicatorId: string, fileId: string) {
    const currentStore = store.getState();
    const url =
      config.apiUrl + `/api/${currentStore.user.info.contractId}/request/file/${indicatorId}/download/${fileId}`;

    return this.client.getBlob(url);
  }

  upload(indicatorId: string, file: File, type: ATTACHMENT_TYPE) {
    const currentStore = store.getState();
    const url = config.apiUrl + `/api/${currentStore.user.info.contractId}/request/file/${indicatorId}/attachment`;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    return this.client.post(url, formData);
  }
}

export default new IndicatorFileRepository(Request);
