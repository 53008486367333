import React from 'react';
import SheetForm from './component/sheet.form';
import { CreateSheet } from '../../store/types/sheet.type';
import SheetRepository from '../../services/repository/SheetRepository';
import {useHistory, useParams} from 'react-router-dom';
import { useGetSheetDetail } from '../../services/hooks/sheet.hook';
import {AppUrls} from "../../constants";

const SheetEdit: React.FC = () => {
  const { id } = useParams();
  const [sheet] = useGetSheetDetail(id);
  const history = useHistory();

  const update = (sheetValue: CreateSheet) => {
    const data = {
      ...sheetValue,
      approvers: sheetValue.approvers.map((id) => ({ userId: id })),
      validators: sheetValue.validators.map((id) => ({ userId: id })),
      suppliers: sheetValue.suppliers.map((id) => ({ userId: id })),
    };

    SheetRepository.edit(id, data)
      .then((response) => {
        history.push(AppUrls.SHEETS);
      })
      .catch((response) => {
        console.error(response);
      });
  };

  if (!sheet) {
    return null;
  }

  const reformedSheet = {
    ...sheet,
    approvers: sheet.approvers.map((u) => u.id),
    suppliers: sheet.suppliers.map((u) => u.id),
    validators: sheet.validators.map((u) => u.id),
  };

  return (
    <div className="sheet-edit">
      <SheetForm onSubmit={update} initialValue={reformedSheet} baseData={sheet} />
    </div>
  );
};

export default SheetEdit;
