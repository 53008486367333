import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import userReducer from './user_reducer';
import loadingReducers from './loading_reducer';
import errorReducers from './error_reducer';
import userRequestsReducer from './user_requests_reducer';
import SheetReducer from './sheet_reducer';
import toastsReducer from './toasts_reducer';
import paramsReducer from './params_reducer';

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    user: userReducer,
    userRequests: userRequestsReducer,
    sheet: SheetReducer,
    loading: loadingReducers,
    error: errorReducers,
    toasts: toastsReducer,
    params: paramsReducer,
  });

export default rootReducer;
