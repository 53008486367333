export enum Constants {
  DATA_LOADING = 'DATA_LOADING',
  ERROR = 'ERROR',
  ERROR_INTERVENTION = 'ERROR_INTERVENTION',
  LOG_IN = 'login',
  LOG_OUT = 'logout',
  NOTIFICATION_PERIMETER_LABEL = 'perimeter',
  SET_NOTIFICATION_CONFIGURATION = 'SET_NOTIFICATION_CONFIGURATION',
}

export enum RequestAttachmentType {
  LOCATION_PLAN = 'LOCATION_PLAN',
  OTHER_TYPE = 'OTHER',
}

export enum ERROR {
  SET = 'ERROR.SET',
}

export enum Status {
  STATUS_DRAFT = 'draft',
  STATUS_TO_APPROVE = 'to_approve',
  STATUS_TO_VALIDATE = 'to_validate',
  STATUS_REFUSED = 'refused',
  STATUS_VALIDATED = 'validated',
}

export enum Deadlines {
  ON_TIME = 'ontime_deadlines',
  LIMIT = 'limit_deadlines',
  LATE = 'late_deadlines',
}

export enum Roles {
  ROLE_SUEZ = 'ROLE_SUEZ',
  ROLE_CLIENT = 'ROLE_CLIENT',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER_ADMIN = 'ROLE_ADMIN',
}

export enum RequestListPages {
  ALL = 'all',
  MINE = 'mine',
  TO_PRODUCE = 'toProduce',
  ITEMS_PER_PAGE = 20,
  PAGE_RANGE = 5,
}

export enum RequestAttributes {
  CONTACT_TYPE_SUBSCRIBER = 'contactTypeSubscriber',
  CONTACT_TYPE_OFFICE = 'contactTypeOffice',
  CONTACT_TYPE_ORGANISATION = 'contactTypeOrganisation',
  SUBSCRIBER_TYPE = 'subscriber',
  OFFICE_TYPE = 'office',
  ORGANISATION_TYPE = 'organisation',
}

export enum HttpCode {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  NOT_FOUND = 404,
  ACCESS_DENIED = 403,
  OUPS = 500,
  UNSUPPORTED_MEDIA = 415,
}

export enum AppUrls {
  HOME = '/',
  INDICATORS = '/indicateurs',
  INDICATOR = '/indicateurs/:id',
  LOGOUT = '/logout',
  SHEETS = '/campagnes',
  CREATE_SHEETS = '/campagnes/nouveau',
  EDIT_SHEET = '/campagnes/:id',
}

export enum ErrorRoutes {
  NOT_FOUND = '/error/unknown',
  ACCESS_DENIED = '/error/access-denied',
  OUPS = '/error/oups',
  SSO_ERROR = '/error/sso',
}

export enum InterventionsIds {
  PLANNED = '3',
  DONE_4 = '4',
  DONE_5 = '5',
  ONGOING = '7',
}

export enum DelayFormats {
  'HOURS',
  'DAYS',
}

export enum DefaultSelectedItems {
  operationTypes = 'other',
  cleansingTypes = 'unknown',
}

export enum notificationPerimeters {
  'mine' = 'owner',
  'all' = 'all',
}

export enum notificationsEvents {
  'newComment' = 'COMMENT_POSTED_EVENT',
  'newAttachment' = 'DOCUMENT_ADDED_EVENT',
  'statusChange' = 'STATUS_CHANGED_EVENT',
}

export const months = [
  "Toute l'année",
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];
const start_year = 2019;
const end_year = new Date().getFullYear();
export const years = new Array(end_year - start_year + 1).fill(undefined).map((_, i) => i + start_year + '');
export const phoneRegExp = /^((0|\+33)[1-9][0-9]{8}){0,1}$/;
export const NOT_SET_VALUE = '__NOT_SET__';

export const MAP_URI = 'https://{s}.tile.osm.org/{z}/{x}/{y}.png';
export const MAP_AZURE_URI =
  'https://atlas.microsoft.com/map/tile?subscription-key={subscriptionKey}&api-version=2.0&tilesetId={tilesetId}&zoom={z}&x={x}&y={y}&tileSize=256&language={language}&view={view}';
export const MAP_AZURE_KEY = 'drrPYMATrq0wlcx9qGmeieQtYRwyAjspN7mktcUtFUo';
export const requestAcceptedMimeTypes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/gif',
  'text/csv',
];

export enum Recurrence {
  RECURRENCE_YEARLY = 'yearly',
  RECURRENCE_MONTHLY = 'monthly',
  RECURRENCE_BIANNUAL = 'biannual',
  RECURRENCE_QUARTERLY = 'quarterly',
  RECURRENCE_PUNCTUAL = 'oneTime',
}

export const regexYearlyRecurrence = /^[0-9]{4}$/g;
export const regexMonthlyRecurrence = /^[A-Za-zÀ-ú]*\s[0-9]{4}$/g;
export const regexQuerterlyRecurrence = /^T[1-4]\s[0-9]{4}$/g;
export const regexBiannualRecurrence = /^S[1-2]\s[0-9]{4}$/g;
export const quarterlyMonths: any = { T1: '06', T2: '09', T3: '12', T4: '03' };
export const biannualMonths: any = { S1: '12', S2: '06' };
export const endContractYear = 2032;
export const initContractYear = 2021;
