import React from 'react';
import SheetForm from './component/sheet.form';
import { CreateSheet } from '../../store/types/sheet.type';
import SheetRepository from '../../services/repository/SheetRepository';
import {AppUrls} from "../../constants";
import {useHistory} from "react-router-dom";

const SheetCreate: React.FC = () => {
  const history = useHistory();

  const create = (sheetValue: CreateSheet) => {
    const data = {
      ...sheetValue,
      approvers: sheetValue.approvers.map((id) => ({ userId: id })),
      validators: sheetValue.validators.map((id) => ({ userId: id })),
      suppliers: sheetValue.suppliers.map((id) => ({ userId: id })),
    };

    SheetRepository.create(data)
      .then((response) => {
        history.push(AppUrls.SHEETS);
      })
      .catch((response) => {
        console.error(response);
      });
  };

  return (
    <div className="sheet-create">
      <SheetForm onSubmit={create} />
    </div>
  );
};

export default SheetCreate;
