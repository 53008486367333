import React, { useEffect, useState } from 'react';
import { SzButton, SzModal } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import { INDICATOR_STATUS, IndicatorStatusType } from '../../../../store/types/indicator.type';
import * as yup from 'yup';

interface Props {
  onClose?: () => void;
  onValidate: (status: IndicatorStatusType) => void;
  show: boolean;
}

const ModalValidatedStatus: React.FC<Props> = (props) => {
  const [show, setShow] = useState(props.show);
  const { t } = useTranslation('indicator');

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const trans = (key: string): string => {
    return t('page.modal.validated.' + key);
  };

  return (
    <SzModal handleClose={() => props.onClose?.()} hideCloseBtn centered title={trans('title')} size="lg" show={show}>
      <p className="text-center my-5">{trans('message')}</p>

      <div className="d-flex justify-content-around">
        <SzButton
          variant="secondary"
          onClick={() => {
            setShow(false);
            props.onClose?.();
          }}
        >
          {trans('button_cancel')}
        </SzButton>
        <SzButton variant="primary" onClick={() => props.onValidate(INDICATOR_STATUS.VALIDATED)}>
          {trans('button_validate')}
        </SzButton>
      </div>
    </SzModal>
  );
};

export default ModalValidatedStatus;
