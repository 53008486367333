import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import IndicatorReportingRepository from '../repository/IndicatorReportingRepository';
import { GetOneIndicator } from '../../store/types/one-indicator.type';

export const useGetOneIndicator = (
  indicatorId: string
): [GetOneIndicator | undefined, Dispatch<SetStateAction<GetOneIndicator | undefined>>] => {
  const [indicatorsReporting, setIndicatorsReporting] = useState<GetOneIndicator>();

  useEffect(() => {
    IndicatorReportingRepository.get(indicatorId).then(({ data }) => {
      setIndicatorsReporting(data);
    });
  }, [indicatorId]);

  return [indicatorsReporting, setIndicatorsReporting];
};
