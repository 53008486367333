import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { useGetOneIndicator } from '../../services/hooks/indicator.hook';
import { SzIcon } from '@suezenv/react-theme-components';
import { AppUrls } from '../../constants';
import { useTranslation } from 'react-i18next';
import './indicator.scss';
import IndicatorHeader from './component/indicator.header';
import IndicatorDetail from './component/indicator.detail';
import IndicatorStatusButton from './component/indicator.status-button';
import IndicatorComments from './component/indicator.comments';
import IndicatorTimeline from './component/timeline/indicator.timeline';

const Indicator: React.FC = () => {
  const { t } = useTranslation('indicator');

  const trans = (key: string): string => {
    return t('page.' + key);
  };

  const { id } = useParams<{ id: string }>();
  const [indicator] = useGetOneIndicator(id);

  if (!indicator) {
    return null;
  }

  const { data } = indicator;

  return (
    <div className="indicator-wrapper container-fluid my-5">
      <div className="back-wrapper">
        <Link to={AppUrls.INDICATORS}>
          <SzIcon className="back" icon="arrow-left" variant="line" /> {trans('back_to_list')}
        </Link>
      </div>

      <div className="my-5">
        <h1>{trans('title') + ` ${data.indicatorCode}`}</h1>
      </div>

      <div className="indicator-header-title">
        <IndicatorStatusButton indicator={indicator} />
        <IndicatorHeader indicator={indicator} />
        <IndicatorDetail indicator={indicator} />
        <IndicatorTimeline indicator={indicator} />
        <IndicatorComments indicator={indicator} />
      </div>
    </div>
  );
};

export default Indicator;
