import React, { useEffect, useState } from 'react';
import IndicatorStatusRepository from '../repository/IndicatorStatusRepository';
import { IndicatorStatus } from '../../store/types/indicator.comment.type';

export const useIndicatorStatus = (indicatorId: string): [IndicatorStatus[], any] => {
  const [status, setStatus] = useState<IndicatorStatus[]>([]);

  useEffect(() => {
    IndicatorStatusRepository.listStatus(indicatorId).then(({ data }) => {
      setStatus(data);
    });
  }, [indicatorId]);

  return [status, setStatus];
};

export const useIndicatorComments = (indicatorId: string): [IndicatorStatus[], any] => {
  const [status] = useIndicatorStatus(indicatorId);
  const [comments, setComments] = useState<IndicatorStatus[]>([]);

  useEffect(() => {
    setComments(status.filter((status) => status.comment));
  }, [status]);

  return [comments, setComments];
};
