import { IndicatorFilterInterface } from '../../store/types/indicator.type';
import { DateService } from '../DateService';
import { Request } from '../Request';

export abstract class AbstractRepository {
  client: Request;

  private filterNamesConversion:
    | {
        indicator: string;
        recurrence: string;
        startDate: string;
        endDate: string;
      }
    | any = {
    indicator: 'indicatorCode',
    recurrence: 'recurrence',
    startDate: 'validationLimitDateStart',
    endDate: 'validationLimitDateEnd',
  };

  constructor(request: Request) {
    this.client = request;
  }
  public prepareFilters(filters: IndicatorFilterInterface): any {
    let queryFilters: any = [];
    let filterFields: any = [];

    queryFilters = { q: filters.q ?? ' ' };

    if (filters.status) {
      queryFilters = { ...queryFilters, status: filters.status.value };
    }

    Object.entries(filters).map((filter: any) => {
      const filterName = filter[0];
      const filterValues = filter[1];
      if (null === filterValues || !this.filterNamesConversion[filterName]) {
        return null;
      }
      const filterNameConvert = this.filterNamesConversion[filterName];
      let value = filterValues.value;
      if (
        filterNameConvert === this.filterNamesConversion.startDate ||
        filterNameConvert === this.filterNamesConversion.endDate
      ) {
        value = DateService.dateToString(filterValues);
      }
      if (filterNameConvert === this.filterNamesConversion.recurrence) {
        value = filterValues;
      }
      filterFields[`filterFields[${filterNameConvert}]`] = value;
      queryFilters = { ...queryFilters, ...filterFields };
    });

    return queryFilters;
  }
}
