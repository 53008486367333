import { DateService } from '../../../services/DateService';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SzButton, SzTypographie } from '@suezenv/react-theme-components';
import { GetOneIndicator, INDICATOR_TYPE } from '../../../store/types/one-indicator.type';
import { FileDownloader } from '../../../services/FileDownloader';
import { generateInstanceLabel } from '../../../helper/format-data.helper';
import { getValueOrDefaultIfEmpty } from '../../../services/Utils/string.utils';

interface Props {
  indicator: GetOneIndicator;
}

const IndicatorHeader: React.FC<Props> = ({ indicator }) => {
  const { t } = useTranslation('indicator');

  const trans = (key: string): string => {
    return t('page.' + key);
  };

  const { data, currentStatus } = indicator;

  // check if reportFile has an id because by default, has a null value.
  const reportFile = (data.indicatorType.id === INDICATOR_TYPE.REPORT && data.reportFile && data.reportFile.id) ? data.reportFile : null;

  return (
    <div className="infobox row no-gutters justify-content-between">
      <div className="col value-presentation inversed py-2">
        <span className="label">{trans('indicator')}</span>
        <span className="value">{getValueOrDefaultIfEmpty(data.indicatorName)}</span>
      </div>
      <div className="col value-presentation inversed py-2">
        <span className="label">{trans('instance')}</span>
        <span className="value">
          {getValueOrDefaultIfEmpty(generateInstanceLabel(data.recurrence.id, data.validationLimitDate!))}
        </span>
        <span className="label">{data.recurrence.label}</span>
      </div>
      <div className="col value-presentation inversed py-2">
        <span className="label">{trans('production_date')}</span>
        <span className="value">{DateService.formatDate(data.validationLimitDate)}</span>
      </div>
      <div className="col value-presentation inversed py-2">
        <span className="label">
          {data.indicatorType.id === INDICATOR_TYPE.VALUE ? trans('value') : trans('sheet')}
        </span>
        <span className="value">
          {data.indicatorType.id === INDICATOR_TYPE.VALUE ? getValueOrDefaultIfEmpty(data.value) : ''}

          {data.indicatorType.id === INDICATOR_TYPE.REPORT && !reportFile ? <>{getValueOrDefaultIfEmpty(null)}</> : ''}

          {reportFile && (
            <>
              <SzButton
                className={'btn-download'}
                type="button"
                onClick={() => FileDownloader.downloadFile(indicator.id, reportFile!)}
              >
                {trans('download_report')}
              </SzButton>
            </>
          )}
        </span>
      </div>
      <div className="col value-presentation inversed py-2">
        <span className="label">{trans('status')}</span>
        <span className="value">
          <span className={`status-sticker mr-2 mb-0 status-sticker-${currentStatus.status.id}`} />
          {t('reporting.' + currentStatus.status.id)}
        </span>
      </div>
    </div>
  );
};

export default IndicatorHeader;
