import { endContractYear, initContractYear, Recurrence, Status } from '../../../constants';
import { generateInstanceLabel } from '../../../helper/format-data.helper';
import { DateService } from '../../../services/DateService';
import { ISelectOption } from '../../../store/types';

export const getStatusOptions = (translate: any): ISelectOption[] => {
  return [
    {
      label: translate(`reporting.${Status.STATUS_DRAFT}`),
      value: Status.STATUS_DRAFT,
    },
    {
      label: translate(`reporting.${Status.STATUS_TO_APPROVE}`),
      value: Status.STATUS_TO_APPROVE,
    },
    {
      label: translate(`reporting.${Status.STATUS_TO_VALIDATE}`),
      value: Status.STATUS_TO_VALIDATE,
    },
    {
      label: translate(`reporting.${Status.STATUS_REFUSED}`),
      value: Status.STATUS_REFUSED,
    },
    {
      label: translate(`reporting.${Status.STATUS_VALIDATED}`),
      value: Status.STATUS_VALIDATED,
    },
  ];
};

export const getIndicators = (sheets: { code: string }[], translate: any): ISelectOption[] => {
  let sheetsOptions: ISelectOption[] = [];
  sheets &&
    sheets.map((sheet: { code: string }) => {
      sheetsOptions.push({
        value: sheet.code,
        label: translate(sheet.code),
      });
    });
  return sheetsOptions;
};

export const getInstances = (): ISelectOption[] => {
  let instancesOptions: any;
  let instancesYearlyOptions: ISelectOption[] = [];
  let instancesMonthlyOptions: ISelectOption[] = [];
  let instancesQuarterlyOptions: ISelectOption[] = [];
  let instancesBiannualOptions: ISelectOption[] = [];
  const quarterlyMonths: string[] = ['01', '04', '07', '10'];
  const biannualMonths: string[] = ['01', '07'];

  for (let year = initContractYear; year < endContractYear; year++) {
    const instanceLabel = generateInstanceLabel(
      Recurrence.RECURRENCE_YEARLY,
      DateService.dateToString(new Date(`01/01/${year}`))
    );
    instancesYearlyOptions.push({
      value: instanceLabel,
      label: instanceLabel,
    });
    for (let month = 1; month <= 12; month++) {
      const instanceLabel = generateInstanceLabel(
        Recurrence.RECURRENCE_MONTHLY,
        DateService.dateToString(new Date(`${month}/01/${year}`))
      );
      instancesMonthlyOptions.push({
        value: DateService.dynamicFormat(`${month}/01/${year}`, 'MMMM Y'),
        label: instanceLabel,
      });
    }
    quarterlyMonths.map((month: any) => {
      const instanceLabel = generateInstanceLabel(
        Recurrence.RECURRENCE_QUARTERLY,
        DateService.dateToString(new Date(`${month}/01/${year}`))
      );
      instancesQuarterlyOptions.push({
        value: instanceLabel,
        label: instanceLabel,
      });
    });
    biannualMonths.map((month: any) => {
      const instanceLabel = generateInstanceLabel(
        Recurrence.RECURRENCE_BIANNUAL,
        DateService.dateToString(new Date(`${month}/01/${year}`))
      );
      instancesBiannualOptions.push({
        value: instanceLabel,
        label: instanceLabel,
      });
    });
  }
  instancesOptions = [
    {
      label: 'Mensuel',
      options: instancesMonthlyOptions,
    },
    {
      label: 'Trimestriel',
      options: instancesQuarterlyOptions,
    },
    {
      label: 'Semestriel',
      options: instancesBiannualOptions,
    },
    {
      label: 'Annuel',
      options: instancesYearlyOptions,
    },
  ];

  return instancesOptions;
};
