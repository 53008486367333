import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from './reducers';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { AppActions } from './types';

const composeEnhancer: typeof compose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const history = createBrowserHistory();
export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(
  rootReducer(history),
  composeEnhancer(applyMiddleware(routerMiddleware(history), thunk as ThunkMiddleware<AppState, AppActions>))
);
