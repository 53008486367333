import React from 'react';
import { GetOneIndicator } from '../../../store/types/one-indicator.type';
import { useTranslation } from 'react-i18next';
import IndicatorNewComment from './indicator.new-comment';
import IndicatorCommentItem from './indicator.comment-item';
import { useIndicatorComments } from '../../../services/hooks/indicator-status.hook';
import IndicatorNewAttachment from './indicator.new-attachment';

interface Props {
  indicator: GetOneIndicator;
}

const IndicatorComments: React.FC<Props> = ({ indicator }) => {
  const { t } = useTranslation('indicator');

  const trans = (key: string): string => {
    return t('page.comments.' + key);
  };

  const [comments] = useIndicatorComments(indicator.id);

  return (
    <div className="indicator-comments-wrapper infobox">
      <div className="position-relative">
        <div className="button-add-attachment-wrapper">
          <IndicatorNewAttachment indicator={indicator} />
        </div>
      </div>

      <h2 className="sub-title">{trans('title')}</h2>

      <IndicatorNewComment indicator={indicator} />

      <div className="indicator-comments-list">
        {comments.map((comment) => (
          <IndicatorCommentItem key={comment.id} comment={comment} />
        ))}
      </div>
    </div>
  );
};

export default IndicatorComments;
