import * as Yup from 'yup';

export default {
  status: {
    schema: Yup.string().required('required_validation'),
    label: 'status',
    icon: '',
    name: 'status',
    type: 'select',
    placeholder: '',
    required: false,
  },
  indicator: {
    schema: Yup.string().required('required_validation'),
    label: 'indicator',
    icon: '',
    name: 'indicator',
    type: 'select',
    placeholder: '',
    required: false,
  },
  instance: {
    schema: Yup.string().required('required_validation'),
    label: 'instance',
    icon: '',
    name: 'instance',
    type: 'select',
    placeholder: '',
    required: false,
  },
};
