const PAGINATION_TOTAL_COUNT = 'x-pagination-total-count';
const PAGINATION_PAGE_COUNT = 'x-pagination-page-count';
const PAGINATION_CURRENT_PAGE = 'x-pagination-current-page';
const PAGINATION_PER_PAGE = 'x-pagination-per-page';

const PAGINATION_HEARDERS = [
  PAGINATION_TOTAL_COUNT,
  PAGINATION_PAGE_COUNT,
  PAGINATION_CURRENT_PAGE,
  PAGINATION_PER_PAGE,
];

const getPaginationInfo = (headers: { [s: string]: unknown } | ArrayLike<unknown>): any => {
  let pagination: any = [];
  Object.entries(headers).map((header: any) => {
    if (PAGINATION_HEARDERS.includes(header[0])) {
      pagination[header[0]] = header[1];
    }
    return pagination;
  });

  return pagination;
};

export default getPaginationInfo;
