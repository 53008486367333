import { RequestListPages } from '../constants';
import UserRequestsService from '../services/UserRequestsService';
import { initialStateFilter } from '../store/reducers/user_requests_reducer';
import { IndicatorFilterInterface, INDICATOR_REQUEST_TYPE } from '../store/types/indicator.type';

export const setRequestData = (
  type: RequestListPages | INDICATOR_REQUEST_TYPE,
  page = 1,
  filter: IndicatorFilterInterface = initialStateFilter,
  sortField = 'indicatorCode',
  sortDirection = 'asc'
) => {
  switch (type) {
    case RequestListPages.ALL:
      return UserRequestsService.getAllUserRequest(
        page,
        filter,
        sortField,
        sortDirection,
        RequestListPages.ITEMS_PER_PAGE
      );
    case RequestListPages.MINE:
      return UserRequestsService.getMyIndicatorUserRequest(
        page,
        filter,
        sortField,
        sortDirection,
        RequestListPages.ITEMS_PER_PAGE
      );
    case RequestListPages.TO_PRODUCE:
      return UserRequestsService.getToProduceUserRequest(
        page,
        filter,
        sortField,
        sortDirection,
        RequestListPages.ITEMS_PER_PAGE
      );
  }
};
