import React, { useState } from 'react';
import { ATTACHMENT_TYPE, GetOneIndicator } from '../../../store/types/one-indicator.type';
import { useTranslation } from 'react-i18next';
import { SzButton, SzModal } from '@suezenv/react-theme-components';
import { Form, Formik } from 'formik';
import FileUploader from '../../../components/elements/file-uploader/file-uploader';
import * as yup from 'yup';
import IndicatorFileRepository from '../../../services/repository/IndicatorFileRepository';

interface Props {
  indicator: GetOneIndicator;
}

interface AddAttachment {
  files: File[];
}

const IndicatorNewAttachment: React.FC<Props> = ({ indicator }) => {
  const [show, setShow] = useState<boolean>(false);
  const { t } = useTranslation('indicator');

  const trans = (key: string): string => {
    return t('page.new_attachment.' + key);
  };

  const initialValue: AddAttachment = {
    files: [],
  };

  const addAttachment = async (values: AddAttachment) => {
    for (const file of values.files) {
      await IndicatorFileRepository.upload(indicator.id, file, ATTACHMENT_TYPE.ATTACHMENT);
    }

    window.location.reload();
  };

  const schema = yup.object().shape<Partial<AddAttachment>>({
    files: yup.array<File>().min(1),
  });

  return (
    <>
      <SzButton variant="secondary" icon="attachment" alignIcon="right" onClick={() => setShow(true)}>
        {trans('add_attachment')}
      </SzButton>
      <SzModal handleClose={() => setShow(false)} hideCloseBtn centered title={trans('title')} size="lg" show={show}>
        <Formik initialValues={initialValue} onSubmit={addAttachment} validateOnMount validationSchema={schema}>
          {({ handleSubmit, isValid, setFieldValue }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <div>
                  <FileUploader
                    className="text-center"
                    canDeleteFile
                    showCurrentFiles
                    dragAndDropText={trans('drag_drop_text')}
                    onChange={(files) => setFieldValue('files', files)}
                  />
                </div>

                <div className="d-flex justify-content-around">
                  <SzButton
                    variant="secondary"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    {trans('button_cancel')}
                  </SzButton>
                  <SzButton isDisabled={!isValid} type="submit" variant="primary">
                    {trans('button_validate')}
                  </SzButton>
                </div>
              </Form>
            );
          }}
        </Formik>
      </SzModal>
    </>
  );
};

export default IndicatorNewAttachment;
