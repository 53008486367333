import axios, { AxiosRequestConfig } from 'axios';
import { store } from '../store';
import { Constants } from '../constants';
import { AuthenticateService } from './AuthenticateService';
import { config } from '../config';
import { loadingSpinner } from '../store/actions';

const GET_INTERVENTIONS_URI = '/api/interventions';
export class Request {
  public static cancel: any;
  private base: string;
  private CancelToken: any;

  constructor() {
    this.base = config.apiUrl as string;
    this.CancelToken = axios.CancelToken;
  }

  public async get(endpoint: string, config: Partial<AxiosRequestConfig> = {}, cancelOption?: boolean) {
    Request.cancel && Request.cancel();
    const httpInstance = this.getHttpInstance();
    let param: AxiosRequestConfig = {
      ...config,
    };

    if (cancelOption) {
      param.cancelToken = new this.CancelToken(function executor(c: any) {
        Request.cancel = c;
      });
    }

    return httpInstance.get(endpoint, param);
  }

  public async getBlob(endpoint: string) {
    Request.cancel && Request.cancel();
    const httpInstance = this.getHttpInstance();
    let param = { responseType: 'blob' as 'blob' };

    return httpInstance.get(endpoint, param);
  }

  public async getBlobWithPost(endpoint: string, params: any) {
    Request.cancel && Request.cancel();
    const httpInstance = this.getHttpInstance();
    params.responseType = 'blob' as 'blob';
    return httpInstance.post(endpoint, params);
  }

  public async post(endpoint: string, body: any, cancelOption?: boolean) {
    Request.cancel && Request.cancel();
    const httpInstance = this.getHttpInstance();
    let param = {};
    if (cancelOption) {
      param = {
        cancelToken: new this.CancelToken(function executor(c: any) {
          Request.cancel = c;
        }),
      };
    }
    return httpInstance.post(endpoint, body, param);
  }

  public async put(endpoint: string, body: any) {
    return this.getHttpInstance().put(endpoint, body);
  }

  public async delete(endpoint: string, body: any) {
    return this.getHttpInstance().delete(endpoint, body);
  }

  public createFormData(formData: FormData, key: string, data: any) {
    if (data === Object(data) || Array.isArray(data)) {
      let args: any = [];
      for (const i in data) {
        args[i] = data[i]['value'];
      }
      formData.append(key, args.join(','));
    } else {
      formData.append(key, data);
    }
  }

  public bodyToFormData(body: any) {
    const formData = new FormData();
    Object.keys(body).map((key: any) => {
      return this.createFormData(formData, key, body[key]);
    });
    return formData;
  }

  public async refreshToken() {
    const body = JSON.stringify({
      refresh_token: sessionStorage.getItem('refreshToken'),
    });
    const endpoint = `/token/refresh`;
    const httpInstance = this.getHttpInstance();
    return httpInstance.post(endpoint, body);
  }

  private getHttpInstance() {
    const { base } = this;
    const httpInstance = axios.create({
      baseURL: `${base}`,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        authorization: `Bearer ${localStorage.getItem('token-atlas-indicateur')}`,
      },
    });

    httpInstance.interceptors.response.use(
      (response: any) => response,
      (error: { response: { config: any; status: any; data: { message: any } }; message: any }) => {
        if (axios.isCancel(error)) {
          return;
        }
        if (error.response) {
          const {
            config: { url },
            status,
            data: { message },
          } = error.response;
          const isJwtError = 401 === status;
          if (url.includes(GET_INTERVENTIONS_URI)) {
            store.dispatch({
              payload: { isError: true, status, message },
              type: Constants.ERROR_INTERVENTION,
            });

            return;
          }
          if (isJwtError && url.includes('token-check')) {
            localStorage.clear();
            AuthenticateService.logout();
          } else {
            store.dispatch({
              payload: { isError: true, status, message },
              type: Constants.ERROR,
            });
          }
        } else {
          store.dispatch({
            payload: { isError: true, status: 0, message: error.message },
            type: Constants.ERROR,
          });
        }
        store.dispatch(loadingSpinner(false));

        return Promise.reject(error);
      }
    );
    return httpInstance;
  }

  public cancelRequest() {
    Request.cancel && Request.cancel();
  }
}

export default new Request();
