import moment from 'moment';

export class DateService {
  public static dateDiff(date1: Date, date2: Date) {
    const daysDiff = this.daysDiff(date1, date2);
    if (daysDiff > 7) {
      //more then one week
      const weekDiff = moment(date1).diff(moment(date2), 'week');
      return {
        format: 'weeks',
        value: weekDiff,
      };
    }
    return {
      format: 'days',
      value: daysDiff,
    };
  }

  public static formatDate(date?: string, options = {}) {
    if (!date) {
      return '';
    }

    return DateService.stringToDate(date).toLocaleDateString('fr-FR', options);
  }

  public static fromProcessingDuration(date: string) {
    return moment(date, 'YYYY/MM/DD').format('YYYY-MM-DDTHH:mm:ss');
  }

  public static fromStatusHistory(date: string) {
    return moment(date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DDTHH:mm:ss');
  }

  public static fromYMDHMS(date: string) {
    return moment.utc(date).local().format('YYYY-MM-DDTHH:mm:ss');
  }

  public static stringToDate(date: string) {
    return moment(date).toDate();
  }

  public static dateToString(date: Date) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
  }

  public static subscribeDateByMonth(date: string, delta: number, format: string = 'YYYY-MM-DDTHH:mm:ss') {
    return moment(date).subtract(delta, 'months').format(format);
  }

  public static subscribeDateBySemester(date: string, delta: number) {
    const deltaDate =  moment(date).subtract(delta, 'months');
    return Math.ceil(parseInt(deltaDate.format('Q'))/2) + ' ' + deltaDate.format('Y')
  }

  public static dynamicFormat(date: string, format: string) {
    return moment(date).format(format);
  }
  public static daysDiff(date1: Date, date2: Date) {
    return moment(date1).diff(moment(date2), 'days');
  }

  public static isValidDate(date: string): boolean {
    return moment(date).isValid();
  }

  public static addPeriod(date: string, amount: any, unit: any) {
    return moment(date).add(amount, unit).format('YYYY-MM-DDTHH:mm:ss');
  }

  public static formatDateYMD(date: any, functional = false): Date | string | null {
    if (date === null) {
      return null;
    } else {
      const toTwoDigit = (value: number) => `0${value}`.slice(-2);
      if (functional) {
        return `${toTwoDigit(date.getDate())}/${toTwoDigit(date.getMonth() + 1)}/${date.getFullYear()}`;
      }
      return `${date.getFullYear()}-${toTwoDigit(date.getMonth() + 1)}-${toTwoDigit(date.getDate())}`;
    }
  }
}
