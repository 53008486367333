import React from 'react';
import { useTranslation } from 'react-i18next';
import { SheetUser } from '../../../store/types/one-indicator.type';
import { getValueOrDefaultIfEmpty } from '../../../services/Utils/string.utils';

interface Props {
  user: SheetUser;
}

const IndicatorUser: React.FC<Props> = ({ user }) => {
  const { t } = useTranslation('indicator');

  const trans = (key: string): string => {
    return t('page.user.' + key);
  };

  return (
    <div className="bg-gray py-3 row no-gutters justify-content-between">
      <div className="col value-presentation inversed py-2">
        <span className="label">{trans('name')}</span>
        <span className="value">{`${user.firstName} ${user.lastName}`}</span>
      </div>
      <div className="col value-presentation inversed py-2">
        <span className="label">{trans('company')}</span>
        <span className="value">{getValueOrDefaultIfEmpty(user.company)}</span>
      </div>
      <div className="col value-presentation inversed py-2">
        <span className="label">{trans('phone')}</span>
        <span className="value">{getValueOrDefaultIfEmpty(user.phoneNumber)}</span>
      </div>
      <div className="col value-presentation inversed py-2">
        <span className="label">{trans('email')}</span>
        <span className="value">{getValueOrDefaultIfEmpty(user.email)}</span>
      </div>
    </div>
  );
};

export default IndicatorUser;
