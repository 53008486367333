import { AbstractRepository } from './AbstractRepository';
import { config } from '../../config';
import Request from '../Request';
import { AxiosResponse, CancelToken } from 'axios';
import { GetUserRequest } from '../../store/types/user.type';

class UserRepository extends AbstractRepository {
  search(searchQuery = '', cancelToken: CancelToken | undefined = undefined): Promise<AxiosResponse<GetUserRequest[]>> {
    const url = config.apiUrl + `/api/user/`;

    return this.client.get(url, {
      cancelToken: cancelToken,
      params: {
        q: searchQuery,
      },
    });
  }
}

export default new UserRepository(Request);
