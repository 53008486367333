import { INDICATOR_REQUEST_TYPE } from '../types/indicator.type';
import { PARAMS_ACTIONS, ParamsState } from '../types/params.type';

const paramsReducerDefaultState: ParamsState = {
  activeTab: INDICATOR_REQUEST_TYPE.TO_PRODUCE,
  activePage: 1,
};

const paramsReducer = (state = paramsReducerDefaultState, action: any) => {
  switch (action.type) {
    case PARAMS_ACTIONS.SAVE_ACTIVE_TAB:
      return { ...state, ...action.payload };
    case PARAMS_ACTIONS.SAVE_ACTIVE_PAGE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default paramsReducer;
