import React, { FC } from 'react';
import { connect } from 'react-redux';
import { SzPagination } from '@suezenv/react-theme-components';
import { RequestListPages } from '../constants';

interface IPagination {
  data: string[];
  type: RequestListPages;
  onPageHandle: any;
  filter: any;
}

export const getPaginationData = (userRequests: any): any => {
  return userRequests.pagination ? userRequests.pagination : {};
};

const Pagination: FC<IPagination> = (props: IPagination) => {
  const { type, data } = props;

  const onPaginationHandle = (number: number): any => {
    props.onPageHandle(type, number, props.filter);
  };

  const pagination = getPaginationData(data);
  const currentPage = parseInt(pagination['x-pagination-current-page']);

  return (
    <SzPagination
      totalItemsCount={+pagination['x-pagination-total-count'] || 0}
      activePage={currentPage}
      onChange={onPaginationHandle}
      itemsCountPerPage={+pagination['x-pagination-per-page'] || RequestListPages.ITEMS_PER_PAGE}
      pageRangeDisplayed={5} // RequestListPages.PAGE_RANGE
    />
  );
};

const mapStateToProps = (state: { userRequests: { filter: { q: string } } }) => ({
  filter: state.userRequests.filter,
});

export default connect(mapStateToProps)(Pagination);
