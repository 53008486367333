import {TOAST_ACTIONS, ToastItem} from '../actions/toasts';

type ToastStateType = {
  toast: ToastItem;
};

const initialState: ToastStateType = {
  toast: {
    id: '',
    body: '',
    header: '',
    delay: 1000,
    show: false,
  },
};

const toastsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOAST_ACTIONS.SHOW:
      return { ...state, toast: action.payload };
    case TOAST_ACTIONS.HIDE:
      return { ...state, toast: {...state.toast, show: false} };
    default:
      return state;
  }
};

export default toastsReducer;
