import React from 'react';
import { Form } from 'react-bootstrap';
import { ActionMeta, ValueType } from 'react-select/src/types';
import AsyncSelect from 'react-select/async';
import { AsyncProps } from 'react-select/src/Async';

export type AutocompleteProps = {
  onChange: (value: ValueType<any, any>, action: ActionMeta<any>) => void;
  isMulti?: boolean;
  loadOptions: AsyncProps<any>['loadOptions'];
  placeholder?: string;
  label?: string;
  defaultValue?: any;
};

const AutocompleteField: React.FC<AutocompleteProps> = ({ label, ...inputProps }) => {
  const { onChange, defaultValue, isMulti = false, loadOptions, placeholder } = inputProps;

  return (
    <Form.Group>
      <label>{label}</label>
      <AsyncSelect
        placeholder={placeholder}
        isMulti={isMulti}
        loadOptions={loadOptions}
        defaultValue={defaultValue}
        onChange={onChange}
      />
    </Form.Group>
  );
};

export default AutocompleteField;
