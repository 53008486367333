import IndicatorFileRepository from './repository/IndicatorFileRepository';
import {
  Attachment,
  ATTACHMENT_TYPE,
  AttachmentFile,
  GetOneIndicator,
  ReportFile,
} from '../store/types/one-indicator.type';

export class FileDownloader {
  static getFileOfIndicator(indicator: GetOneIndicator, fileId: string) {
    const file = indicator.attachments.find((file) => file.file.id === fileId);

    return file ? file.file : undefined;
  }

  static downloadFile(indicatorId: string, file: AttachmentFile | ReportFile) {
    IndicatorFileRepository.download(indicatorId, file.id).then((response) => {
      const { data } = response;
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(new Blob([data], { type: file.mimeType }));

      link.download = file.originalFileName;
      document.body.appendChild(link);

      link.click();
      link.remove();
    });
  }

  static getFilesByType(indicator: GetOneIndicator, type: ATTACHMENT_TYPE): Attachment[] {
    const { attachments } = indicator;

    return attachments.filter((attachment) => attachment.type === type);
  }
}
