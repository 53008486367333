import { AbstractRepository } from './AbstractRepository';
import { config } from '../../config';
import Request from '../Request';
import { store } from '../../store';
import { AxiosResponse } from 'axios';
import { GetDetailedSheet, GetSheet } from '../../store/types/sheet.type';

class SheetRepository extends AbstractRepository {
  getDetail(sheetId: string): Promise<AxiosResponse<GetDetailedSheet>> {
    const currentStore = store.getState();
    const url = config.apiUrl + `/api/${currentStore.user.info.contractId}/sheet/${sheetId}/detail`;

    return this.client.get(url);
  }

  getAll(filter = {}): Promise<AxiosResponse<GetSheet[]>> {
    const currentStore = store.getState();

    const url = config.apiUrl + `/api/${currentStore.user.info.contractId}/sheet/`;

    return this.client.get(url, {
      params: filter,
    });
  }

  create(sheet: any) {
    const currentStore = store.getState();
    const url = config.apiUrl + `/api/${currentStore.user.info.contractId}/sheet/`;

    return this.client.post(url, sheet);
  }

  edit(sheetId: string, sheet: any) {
    const currentStore = store.getState();
    const url = config.apiUrl + `/api/${currentStore.user.info.contractId}/sheet/${sheetId}`;

    return this.client.put(url, sheet);
  }

  delete(sheetId: string) {
    const currentStore = store.getState();
    const url = config.apiUrl + `/api/${currentStore.user.info.contractId}/sheet/${sheetId}`;

    return this.client.delete(url, {});
  }
}

export default new SheetRepository(Request);
