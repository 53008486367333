import qs from 'qs';
import { login, logout } from '../store/actions';
import { store } from '../store';
import { config } from '../config';
import axiosService from './Request';
import SheetService from './SheetService';
const TOKEN_INFO_URL = '/api/token-check';

export class AuthenticateService {
  public static login() {
    const { token } = qs.parse(window.location.search.substring(1));
    if (!token) {
      localStorage.setItem('redirect-atlas-indicateur', window.location.href);
      window.location = (config.apiUrl + '/saml/login') as any;
    } else {
      localStorage.setItem('token-atlas-indicateur', token.toString());
      this.checkToken();
      let redirectHref = localStorage.getItem('redirect-atlas-indicateur');
      localStorage.removeItem('redirect-atlas-indicateur');
      window.location = redirectHref as any;
    }
  }

  public static checkToken() {
    axiosService.get(`${TOKEN_INFO_URL}`, {}, false).then(function (response) {
      store.dispatch(login(response.data));
      SheetService.getAllSheets(1, 100);
    });
  }

  public static logout(): boolean {
    localStorage.clear();
    store.dispatch(logout());
    window.location = (config.apiUrl + '/saml/logout') as any;
    return true;
  }
}
