import { GetOneIndicator } from '../store/types/one-indicator.type';
import { UserState } from '../store/types/user.type';
import {INDICATOR_STATUS, IndicatorStatusType} from '../store/types/indicator.type';

export const canShowButton = (
  indicator: GetOneIndicator,
  nextStatus: IndicatorStatusType,
  user: UserState
): boolean => {
  switch (nextStatus) {
    case INDICATOR_STATUS.TO_APPROVE:
      return isSupplier(indicator, user);
    case INDICATOR_STATUS.REFUSED:
      if (INDICATOR_STATUS.TO_APPROVE === indicator.currentStatus.status.id) {
        return isApprobator(indicator, user);
      }

      return isValidator(indicator, user);
    case INDICATOR_STATUS.TO_VALIDATE:
      return isApprobator(indicator, user);
    case INDICATOR_STATUS.VALIDATED:
      return isValidator(indicator, user);
  }

  return false;
};

export const isValidator = (indicator: GetOneIndicator, user: UserState): boolean => {
  const {
    data: { validators },
  } = indicator;

  return validators && undefined !== validators.find((currentUser) => currentUser.id === user.info.id);
};

export const isApprobator = (indicator: GetOneIndicator, user: UserState): boolean => {
  const {
    data: { approvers },
  } = indicator;

  return approvers && undefined !== approvers.find((currentUser) => currentUser.id === user.info.id);
};

export const isSupplier = (indicator: GetOneIndicator, user: UserState): boolean => {
  const {
    data: { suppliers },
  } = indicator;

  return suppliers && undefined !== suppliers.find((currentUser) => currentUser.id === user.info.id);
};
