import React, { useRef } from 'react';
import UserRepository from '../../../services/repository/UserRepository';
import axios, { CancelTokenSource } from 'axios';
import { SzInputProps } from '@suezenv/react-theme-components/build/components/SzInput/SzInput';
import AutocompleteField, { AutocompleteProps } from './autocomplete.field';

interface Props {
  onChange: AutocompleteProps['onChange'];
  isMulti?: boolean;
  defaultValue?: any;
  label?: any;
}

const UserAutocomplete: React.FC<Props> = ({ label, onChange, defaultValue, isMulti = false }) => {
  let cancelTokenSource = useRef<CancelTokenSource>();

  return (
    <div className="user-autocomplete">
      <AutocompleteField
        label={label}
        defaultValue={defaultValue}
        isMulti={isMulti}
        loadOptions={(input, callback) => {
          if (cancelTokenSource.current !== undefined) {
            cancelTokenSource.current.cancel();
          }

          cancelTokenSource.current = axios.CancelToken.source();

          if (!input) {
            return;
          }

          UserRepository.search(input, cancelTokenSource.current.token).then((response) => {
            if (response) {
              callback(response.data.map((user) => ({ value: user.id, label: `${user.firstName} ${user.lastName}` })));
            }
          });
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default UserAutocomplete;
