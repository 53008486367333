import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SzButton } from '@suezenv/react-theme-components';
import classNames from 'classnames';
import { GetOneIndicator, INDICATOR_TYPE } from '../../../store/types/one-indicator.type';
import { canShowButton } from '../../../services/ActionAuthorization';
import { INDICATOR_STATUS, IndicatorStatusType } from '../../../store/types/indicator.type';
import ModalValidatedStatus from './modal-status/modal.validated-status';
import ModalValueToApproveStatus, { ValueToApproveType } from './modal-status/modal.value-to-approve-status';
import IndicatorStatusRepository from '../../../services/repository/IndicatorStatusRepository';
import { store } from '../../../store';
import { FormikHelpers } from 'formik';
import IndicatorManager from '../../../services/manager/IndicatorManager';
import { loadingSpinner } from '../../../store/actions';
import ModalReportToApproveStatus, { ReportToApproveType } from './modal-status/modal.report-to-approve-status';
import ModalRefusedStatus, { ValueRefusedType } from './modal-status/modal.refused-status';
import ModalToValidateStatus from './modal-status/modal.to-validate-status';
import { showToast, ToastItem } from '../../../store/actions/toasts';
import { useHistory } from 'react-router-dom';
import { generateInstanceLabel } from '../../../helper/format-data.helper';

interface Props {
  indicator: GetOneIndicator;
}

type ListModal =
  | 'modalValidated'
  | 'modalValueToApprove'
  | 'modalReportToApprove'
  | 'modalReportRefused'
  | 'modalToValidate'
  | '';

const IndicatorStatusButton: React.FC<Props> = ({ indicator }) => {
  const [modalOpened, setModalOpened] = useState<ListModal>('');

  const { t } = useTranslation('indicator');
  let history = useHistory();
  const user = store.getState().user;

  const trans = (key: string): string => {
    return t('page.button.' + key);
  };

  const closeModals = () => {
    setModalOpened('');
  };

  const classes = classNames('button-status justify-content-end');

  const showModalStatus = (nextStatus: IndicatorStatusType) => {
    switch (nextStatus) {
      case INDICATOR_STATUS.TO_VALIDATE:
        setModalOpened('modalToValidate');
        break;
      case INDICATOR_STATUS.VALIDATED:
        setModalOpened('modalValidated');
        break;
      case INDICATOR_STATUS.REFUSED:
        setModalOpened('modalReportRefused');
        break;
      case INDICATOR_STATUS.TO_APPROVE:
        if (indicator.data.indicatorType.id === INDICATOR_TYPE.VALUE) {
          setModalOpened('modalValueToApprove');
        } else {
          setModalOpened('modalReportToApprove');
        }
        break;
    }
  };

  /**
   * @param status nextStatus.
   * @param data
   * @param formikBag
   */
  const submitModals = async (status: IndicatorStatusType, data?: any, formikBag?: FormikHelpers<any>) => {
    const instance = generateInstanceLabel(indicator.data.recurrence.id, indicator.data.validationLimitDate);

    const currentIndicator = {...indicator};

    switch (status) {
      case INDICATOR_STATUS.TO_VALIDATE:
        store.dispatch(loadingSpinner(true));
        IndicatorStatusRepository.updateStatus(currentIndicator.id, status,{ comment: '' }).then(() => {
          const toast: ToastItem = {
            id: 'toast.to_validate',
            body: t('toast.message_approved.body', { instance }),
            header: t('toast.message_approved.header', { name: currentIndicator.data.indicatorCode }),
            show: true,
          };
          store.dispatch(showToast(toast));
          store.dispatch(loadingSpinner(false));
          history.push('/indicateurs');
        });

        break;
      case INDICATOR_STATUS.VALIDATED:
        store.dispatch(loadingSpinner(true));

        IndicatorStatusRepository.updateStatus(currentIndicator.id, status,{ comment: '' }).then(() => {
          const toast: ToastItem = {
            id: 'toast.validated',
            body: t('toast.message_validated.body', { instance }),
            header: t('toast.message_validated.header', { name: currentIndicator.data.indicatorCode }),
            show: true,
          };
          store.dispatch(showToast(toast));
          store.dispatch(loadingSpinner(false));
          history.push('/indicateurs');
        });

        break;
      case INDICATOR_STATUS.REFUSED:
        store.dispatch(loadingSpinner(true));
        const values = data as ValueRefusedType;
        IndicatorStatusRepository.updateStatus(currentIndicator.id, status,values).then(() => {
          const toast: ToastItem = {
            id: 'toast.refused',
            body: t('toast.message_refused.body', { instance }),
            header: t('toast.message_refused.header', { name: currentIndicator.data.indicatorCode }),
            show: true,
          };
          store.dispatch(showToast(toast));
          store.dispatch(loadingSpinner(false));
          history.push('/indicateurs');
        });

        break;
      case INDICATOR_STATUS.TO_APPROVE:
        store.dispatch(loadingSpinner(true));

        const toast: ToastItem = {
          id: 'toast.submited',
          body: t('toast.message_submited.body', { instance }),
          header: t('toast.message_submited.header', { name: currentIndicator.data.indicatorCode }),
          show: true,
        };

        if (currentIndicator.data.indicatorType.id === INDICATOR_TYPE.VALUE) {
          const values = data as ValueToApproveType;
          const bag = formikBag as FormikHelpers<ValueToApproveType>;
          bag.setSubmitting(true);
          await IndicatorManager.supplyValueType(
              currentIndicator,
              values,
              toast,
            () => bag.setSubmitting(false),
            () => bag.setSubmitting(false)
          );
        } else {
          const values = data as ReportToApproveType;
          const bag = formikBag as FormikHelpers<ReportToApproveType>;
          bag.setSubmitting(true);
          await IndicatorManager.supplyReportType(
              currentIndicator,
            values,
            toast,
            () => bag.setSubmitting(false),
            () => bag.setSubmitting(false)
          );
        }

        store.dispatch(loadingSpinner(false));
        history.push('/indicateurs');

        break;
    }
  };

  return (
    <div className={classes}>
      {indicator.nextPossibleStatuses.map((nextStatus) =>
        canShowButton(indicator, nextStatus.id, user) ? (
          <SzButton key={nextStatus.id} className="ml-2" onClick={() => showModalStatus(nextStatus.id)}>
            {trans('next_' + nextStatus.id)}
          </SzButton>
        ) : null
      )}

      {modalOpened === 'modalToValidate' && (
        <ModalToValidateStatus onValidate={submitModals} onClose={closeModals} show />
      )}

      {modalOpened === 'modalValidated' && (
        <ModalValidatedStatus onValidate={submitModals} onClose={closeModals} show />
      )}

      {modalOpened === 'modalValueToApprove' && (
        <ModalValueToApproveStatus onValidate={submitModals} onClose={closeModals} show />
      )}

      {modalOpened === 'modalReportToApprove' && (
        <ModalReportToApproveStatus onValidate={submitModals} onClose={closeModals} show />
      )}

      {modalOpened === 'modalReportRefused' && (
        <ModalRefusedStatus onValidate={submitModals} onClose={closeModals} show />
      )}
    </div>
  );
};

export default IndicatorStatusButton;
