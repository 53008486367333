import React from 'react';
import { SzTable } from '@suezenv/react-theme-components';

export const Table = (props: any) => {
  const { columns, items, rowEvents, rowClasses, tableClasses } = props;
  const defaultSorted: any = [
    {
      dataField: 'name',
      order: 'desc',
    },
  ];
  return (
    <SzTable
      rowClasses={rowClasses}
      columns={columns}
      rowEvents={rowEvents}
      data={items}
      defaultSorted={defaultSorted}
      keyField="id"
      className={tableClasses}
    />
  );
};
