import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';
import { SzTypographie } from '@suezenv/react-theme-components';
import { store } from '../../../store';
import { hideToast } from '../../../store/actions/toasts';

/**
 * @constructor
 */
const ToastList: React.FC = () => {
  const [toast, setToast] = useState({
      id: '',
      body: '',
      header: '',
      delay: 1000,
      show: false
  });

  const state = store.getState();
  if (toast !== state.toasts.toast) {
    setToast(state.toasts.toast)
  }
  const hide = () => {
    setToast({
      ...toast,
      show: false
    });
  };

  return (
    <div className="toast-wrapper">
      <Toast
        key={toast.id}
        onClose={() => {
          store.dispatch(hideToast(toast.id));
          hide()
        }}
        delay={1000}
        show={toast.show}
        autohide
      >
        <Toast.Header>
          <SzTypographie variant="h2" className="mr-auto">
            <span className="sz-icon-line information-circle mr-2" />
            {toast.header}
          </SzTypographie>
        </Toast.Header>
        <Toast.Body>
          <SzTypographie variant="h2">{toast.body}</SzTypographie>
        </Toast.Body>
      </Toast>
    </div>
  );
};

export default ToastList;
