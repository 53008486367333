import { ATTACHMENT_TYPE, GetOneIndicator } from '../../store/types/one-indicator.type';
import { ValueToApproveType } from '../../pages/indicator/component/modal-status/modal.value-to-approve-status';
import IndicatorReportingRepository from '../repository/IndicatorReportingRepository';
import IndicatorFileRepository from '../repository/IndicatorFileRepository';
import IndicatorStatusRepository from '../repository/IndicatorStatusRepository';
import { INDICATOR_STATUS } from '../../store/types/indicator.type';
import { ReportToApproveType } from '../../pages/indicator/component/modal-status/modal.report-to-approve-status';
import { store, history } from '../../store';
import { showToast, ToastItem } from '../../store/actions/toasts';

class IndicatorManager {
  static async supplyValueType(
    indicator: GetOneIndicator,
    values: ValueToApproveType,
    toast: ToastItem,
    success?: () => void,
    error?: () => void
  ) {
    let updatedIndicator = indicator;
    updatedIndicator.data.value = values.value;
    // remove due to create a new comment if set currentStatus.
    delete updatedIndicator.currentStatus;

    try {
      IndicatorReportingRepository.edit(indicator.id, updatedIndicator).then(()=>{
        IndicatorStatusRepository.updateStatus(indicator.id, INDICATOR_STATUS.TO_APPROVE, { comment: values.comment }).then(() => {
          if (values.files.length > 0) {
            for (const file of values.files) {
              IndicatorFileRepository.upload(indicator.id, file, ATTACHMENT_TYPE.REPORT_ATTACHMENT);
            }
          }
          success?.();
          store.dispatch(showToast(toast));
          history.push('/indicateurs');
        });
      });

      return;
    } catch (e) {
      error?.();
      console.error(e);
    }
  }

  static async supplyReportType(
    indicator: GetOneIndicator,
    values: ReportToApproveType,
    toast: ToastItem,
    success?: () => void,
    error?: () => void
  ) {
    try {
      await IndicatorFileRepository.upload(indicator.id, values.report!, ATTACHMENT_TYPE.REPORT);
      const { data } = await IndicatorReportingRepository.get(indicator.id);
      let files = data.attachments;
      files = files.sort((a, b) => (a.updatedAt > b.updatedAt ? -1 : 1));
      const file = files.find((file) => file.type === ATTACHMENT_TYPE.REPORT);

      if (!file) {
        throw new Error('File mismatch');
      }

      const updatedValues: ValueToApproveType = {
        files: values.files,
        comment: values.comment,
        value: file.file.id,
      };

      // report file remove.
      const reportFile = {
        id: file.file.id,
        originalFileName: file.file.originalFileName,
        size: file.file.size,
        mimeType: file.file.mimeType,
      };

      data.data.reportFile = reportFile;

      return IndicatorManager.supplyValueType(data, updatedValues, toast,success, error);
    } catch (e) {
      error?.();
      console.error(e);
    }
  }
}

export default IndicatorManager;
