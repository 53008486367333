import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import SheetRepository from '../repository/SheetRepository';
import { GetDetailedSheet, GetSheet } from '../../store/types/sheet.type';
import { pick } from 'lodash';
import { PaginationHeader } from '../../store/types';
import { store } from '../../store';
import { loadingSpinner } from '../../store/actions';

export interface SheetFilter {
  currentPage?: number;
  itemsPerPage?: number;
  search?: string;
}

export const useGetSheets = (
  filter: SheetFilter = {}
): [GetSheet[] | undefined, Dispatch<SetStateAction<GetSheet[] | undefined>>, PaginationHeader] => {
  const [sheets, setSheets] = useState<GetSheet[]>();
  const [pagination, setPagination] = useState<PaginationHeader>({});

  useEffect(() => {
    store.dispatch(loadingSpinner(true));
    SheetRepository.getAll(filter).then(({ data, headers }) => {
      setSheets(data);
      setPagination(pick(headers, ['x-pagination-total-count', 'x-pagination-per-page', 'x-pagination-current-page']));
      store.dispatch(loadingSpinner(false));
    });
  }, [filter.currentPage, filter.search]);

  return [sheets, setSheets, pagination];
};

export const useGetSheetDetail = (
  sheetId: string
): [GetDetailedSheet | undefined, Dispatch<SetStateAction<GetDetailedSheet | undefined>>] => {
  const [sheet, setSheet] = useState<GetDetailedSheet>();

  useEffect(() => {
    SheetRepository.getDetail(sheetId).then(({ data, headers }) => {
      setSheet(data);
    });
  }, [sheetId]);

  return [sheet, setSheet];
};
