import { UserRequestActionType } from '../types/user-request.type';

export const initialStateFilter = {
  q: '',
  status: null,
  indicator: null,
  instance: null,
  startDate: null,
  endDate: null,
};

const initialState = {
  all: { items: [], pagination: [] },
  mine: { items: [], pagination: [] },
  toProduce: { items: [], pagination: [] },
  filter: initialStateFilter,
};

const userRequestsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UserRequestActionType.SET_ALL_USER_REQUESTS:
      return { ...state, all: action.payload };
    case UserRequestActionType.SET_MY_USER_REQUESTS:
      return { ...state, mine: action.payload };
    case UserRequestActionType.SET_TO_PRODUCE_USER_REQUESTS:
      return { ...state, toProduce: action.payload };
    case UserRequestActionType.TRIGGER_FILTERS:
      return { ...state, filter: action.payload };
    default:
      return state;
  }
};

export default userRequestsReducer;
