import React, {useState} from 'react';
import {Form, Formik} from 'formik';
import {CreateSheet, GetDetailedSheet, SheetRecurrence, SheetType} from '../../../store/types/sheet.type';
import {SzTextArea, SzButton, SzInput, SzSelect} from '@suezenv/react-theme-components';
import UserAutocomplete from '../../../components/elements/autocomplete/user.autocomplete';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {store} from '../../../store';
import {DateService} from "../../../services/DateService";
import './sheet.form.scss';
import {RouterPrompt} from "../../../components/router/RouterPrompt";

interface Props {
    onSubmit: (sheet: CreateSheet) => void;
    initialValue?: Partial<CreateSheet>;
    baseData?: GetDetailedSheet;
}

interface OptionItem {
    value: any;
    label: string;
}

const SheetForm: React.FC<Props> = ({onSubmit, initialValue, baseData}) => {
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false)
    const {info} = store.getState().user;
    const createSheet: CreateSheet = {
        contractId: info.contractId,
        code: '',
        gedUrl: '',
        name: '',
        recurrence: '',
        type: '',
        description: '',
        nbDayNotificationDelayToApprove: 0,
        nbDayNotificationDelayToProvide: 0,
        nbDayNotificationDelayToValidate: 0,
        nbDayToCreate: 0,
        productionDay: 1,
        productionMonth: 0,
        objective: '',
        startDate: '',
        theme: '',
        approvers: [],
        suppliers: [],
        validators: [],
        ...initialValue,
    };

    let [monthDisabled, setMonthDisabled] = useState(true);
    let [dayDisabled, setDayDisabled] = useState(false);

    const {t} = useTranslation('sheet');

    const trans = (key: string) => {
        return t('form.' + key);
    };

    const schema = Yup.object().shape<Partial<CreateSheet>>({
        code: Yup.string().required(),
        name: Yup.string().required(),
        startDate: Yup.string().required(),
        gedUrl: Yup.string().url().nullable(),
    });

    const recurrenceOptions: OptionItem[] = [
        {
            label: 'Ponctuel',
            value: SheetRecurrence.RECURRENCE_PUNCTUAL,
        },
        {
            label: 'Mensuel',
            value: SheetRecurrence.RECURRENCE_MONTHLY,
        },
        {
            label: 'Trimestriel',
            value: SheetRecurrence.RECURRENCE_QUARTERLY,
        },
        {
            label: 'Semestriel',
            value: SheetRecurrence.RECURRENCE_BIANNUAL,
        },
        {
            label: 'Annuel',
            value: SheetRecurrence.RECURRENCE_YEARLY,
        },
    ];

    const typeOptions: OptionItem[] = [
        {
            value: SheetType.TYPE_FILE,
            label: 'Rapport',
        },
        {
            value: SheetType.TYPE_VALUE,
            label: 'Valeur',
        },
    ];

    const getValueOption = (value: any, list: OptionItem[]) => {
        return list.find((element) => element.value === value);
    };

    let approversDefault: OptionItem[] = [];
    let suppliersDefault: OptionItem[] = [];
    let validatorsDefault: OptionItem[] = [];

    if (baseData) {
        approversDefault = baseData.approvers.map((u) => ({label: `${u.firstName} ${u.lastName}`, value: u.id}));
        suppliersDefault = baseData.suppliers.map((u) => ({label: `${u.firstName} ${u.lastName}`, value: u.id}));
        validatorsDefault = baseData.validators.map((u) => ({label: `${u.firstName} ${u.lastName}`, value: u.id}));
    }

    const getDayValue = (day: number, recurrence: string) => {
        if (recurrence === 'punctual') {
            return '';
        } else {
            return String(day) || '';
        }
    };

    const getMonthValue = (month: number, recurrence: string) => {
        if (recurrence === 'yearly') {
            return String(month) || '';
        } else {
            return '';
        }
    };

    const alertDataChangeRender = () => {
        if (shouldBlockNavigation) {
            return <RouterPrompt when={true} title={t("form.warning")} onOK={() => true} onCancel={() => false}/>;
        }
    };

    return (
        <>
            <Formik onSubmit={onSubmit} initialValues={createSheet} validationSchema={schema} validateOnMount>
                {(formik) => {
                    const {handleSubmit, setFieldValue, values, handleChange, isValid, errors} = formik;
                    const onSubmitHandle = (e: any) => {
                        setShouldBlockNavigation(false);
                        handleSubmit(e)
                    }
                    const {
                        code,
                        name,
                        gedUrl,
                        description,
                        type,
                        recurrence,
                        objective,
                        theme,
                        productionDay,
                        productionMonth,
                        nbDayToCreate,
                    } = values;

                    const {
                        nbDayNotificationDelayToApprove,
                        nbDayNotificationDelayToProvide,
                        nbDayNotificationDelayToValidate,
                    } = values;
                    const {startDate} = values;

                    if (recurrence === 'yearly') {
                        setMonthDisabled(false);
                    }
                    if (recurrence === 'punctual') {
                        setDayDisabled(true);
                    }
                    const handleSelectChange = (name: string, e: OptionItem) => {
                        setFieldValue(name, e.value);
                        if (name === 'recurrence') {
                            setMonthDisabled(e.value !== 'yearly');
                            setDayDisabled(e.value === 'punctual');
                        }
                    };

                    const globalHandleChange = (e: React.ChangeEvent<any>) => {
                        setShouldBlockNavigation(true);

                        handleChange(e)
                    }
                    return (
                        <Form onSubmit={onSubmitHandle} className="mt-2 px-3 py-4 bg-white">
                            <h1 className="my-2">{trans('title')}</h1>
                            <div className="row">
                                <div className="col">
                                    <SzInput label={trans('code')} name="code" value={code || ''}
                                             onChange={globalHandleChange}/>
                                </div>
                                <div className="col">
                                    <SzInput label={trans('name')} name="name" value={name || ''}
                                             onChange={globalHandleChange}/>
                                </div>
                            </div>

                            <div className="row my-2">
                                <div className="col">
                                    <SzSelect
                                        name="type"
                                        options={typeOptions}
                                        value={getValueOption(type, typeOptions)}
                                        placeholder={trans('type')}
                                        onChange={(option: OptionItem) => {
                                            handleSelectChange('type', option);
                                        }}
                                    />
                                </div>

                                <div className="col">
                                    <SzSelect
                                        name="recurrence"
                                        options={recurrenceOptions}
                                        value={getValueOption(recurrence, recurrenceOptions) || ''}
                                        placeholder={trans('recurrence')}
                                        onChange={(option: OptionItem) => {
                                            handleSelectChange('recurrence', option);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row my-2">
                                <div className="col">
                                    <SzInput name="gedUrl" value={gedUrl || ''} onChange={globalHandleChange}
                                             label={trans('gedUrl')}/>
                                </div>
                            </div>

                            <div className="row my-2">
                                <div className="col">
                                    <SzInput name="theme" value={theme || ''} onChange={globalHandleChange}
                                             label={trans('theme')}/>
                                </div>

                                <div className="col">
                                    <SzInput name="objective" value={objective || ''} onChange={globalHandleChange}
                                             label={trans('objective')}/>
                                </div>
                            </div>

                            <div className="row my-3">
                                <div className="col">
                                    <SzTextArea
                                        name="description"
                                        value={description || ''}
                                        onChange={globalHandleChange}
                                        label={trans('description')}
                                    />
                                </div>
                            </div>

                            <div className="row my-3">
                                <div className="col">
                                    <SzInput
                                        type="number"
                                        name="productionDay"
                                        value={getDayValue(productionDay, recurrence)}
                                        onChange={globalHandleChange}
                                        label={trans('productionDay')}
                                        disabled={dayDisabled}
                                        className={dayDisabled ? 'day-input disabled' : 'day-input'}
                                    />
                                </div>

                                <div className="col">
                                    <SzInput
                                        type="number"
                                        name="productionMonth"
                                        value={getMonthValue(productionMonth, recurrence)}
                                        onChange={globalHandleChange}
                                        label={trans('productionMonth')}
                                        disabled={monthDisabled}
                                        className={monthDisabled ? 'month-input disabled' : 'month-input'}
                                    />
                                </div>

                                <div className="col">
                                    <SzInput
                                        type="number"
                                        name="nbDayToCreate"
                                        value={String(nbDayToCreate) || ''}
                                        onChange={globalHandleChange}
                                        label={trans('nbDayToCreate')}
                                    />
                                </div>
                            </div>

                            <div className="row my-3">
                                <div className="col">
                                    <SzInput
                                        type="number"
                                        name="nbDayNotificationDelayToProvide"
                                        value={String(nbDayNotificationDelayToProvide) || ''}
                                        onChange={globalHandleChange}
                                        label={trans('nbDayNotificationDelayToProvide')}
                                    />
                                </div>

                                <div className="col">
                                    <SzInput
                                        type="number"
                                        name="nbDayNotificationDelayToApprove"
                                        value={String(nbDayNotificationDelayToApprove) || ''}
                                        onChange={globalHandleChange}
                                        label={trans('nbDayNotificationDelayToApprove')}
                                    />
                                </div>

                                <div className="col">
                                    <SzInput
                                        type="number"
                                        name="nbDayNotificationDelayToValidate"
                                        value={String(nbDayNotificationDelayToValidate) || ''}
                                        onChange={globalHandleChange}
                                        label={trans('nbDayNotificationDelayToValidate')}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <UserAutocomplete
                                        defaultValue={suppliersDefault}
                                        label={trans('suppliers')}
                                        onChange={(users) => {
                                            setFieldValue(
                                                'suppliers',
                                                users.map((user: OptionItem) => user.value)
                                            );
                                        }}
                                        isMulti={true}
                                    />
                                </div>

                                <div className="col">
                                    <UserAutocomplete
                                        defaultValue={approversDefault}
                                        label={trans('approvers')}
                                        onChange={(users) => {
                                            setFieldValue(
                                                'approvers',
                                                users.map((user: OptionItem) => user.value)
                                            );
                                        }}
                                        isMulti={true}
                                    />
                                </div>

                                <div className="col">
                                    <UserAutocomplete
                                        defaultValue={validatorsDefault}
                                        label={trans('validators')}
                                        onChange={(users) => {
                                            setFieldValue(
                                                'validators',
                                                users.map((user: OptionItem) => user.value)
                                            );
                                        }}
                                        isMulti={true}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    {
                                        <SzInput
                                            // @ts-ignore
                                            type="date"
                                            name="startDate"
                                            value={startDate ? DateService.dynamicFormat(startDate, 'YYYY-MM-DD') : ''}
                                            onChange={(e) => {
                                                if (e.target.value) {
                                                    setFieldValue('startDate', DateService.dynamicFormat(e.target.value, 'YYYY-MM-DD\T00:00+00:00'));
                                                }
                                            }}
                                            label={trans('startDate')}
                                        />
                                    }
                                </div>
                            </div>

                            <div className="bg-warning">
                                {
                                    Object.keys(errors).map((key) => (
                                        <div key={key} className="my-1 px-1">
                                            {
                                                // @ts-ignore
                                                errors[key]
                                            }
                                        </div>
                                    ))
                                }
                            </div>

                            <SzButton type="submit" isDisabled={!isValid}>
                                Valider
                            </SzButton>
                        </Form>
                    );
                }}
            </Formik>
            {alertDataChangeRender()}
        </>
    );
};

export default SheetForm;
