import { RequestListPages } from '../../constants';

export interface UserRequestFilterInterface {
  q: string;
}

export enum UserRequestActionType {
  SET_ALL_USER_REQUESTS = 'SET_ALL_USER_REQUESTS',
  SET_MY_USER_REQUESTS = 'SET_MY_USER_REQUESTS',
  SET_TO_PRODUCE_USER_REQUESTS = 'SET_TO_PRODUCE_USER_REQUESTS',
  TRIGGER_FILTERS = 'TRIGGER_FILTERS',
}

export type UserRequestType = RequestListPages.MINE | RequestListPages.ALL | RequestListPages.TO_PRODUCE;
