import React from 'react';
import { useTranslation } from 'react-i18next';
import { IndicatorStatus } from '../../../store/types/indicator.comment.type';
import { DateService } from '../../../services/DateService';

interface Props {
  comment: IndicatorStatus;
}

const IndicatorCommentItem: React.FC<Props> = ({ comment }) => {
  const { t } = useTranslation('indicator');

  const trans = (key: string): string => {
    return t('page.comments.' + key);
  };

  return (
    <div className="comment-item my-3">
      <div className="mb-1">
        <span className="comment-item-date">
          {trans('added_at')} {DateService.dynamicFormat(comment.createdAt, 'DD/MM/YYYY à HH:mm')}
        </span>
      </div>

      <div className="comment-item-user mb-1">
        <span>
          {comment.applicationUser.firstName} {comment.applicationUser.lastName}
        </span>
      </div>

      <p className="mb-0">{comment.comment}</p>
    </div>
  );
};

export default IndicatorCommentItem;
