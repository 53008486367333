export enum SheetActionType {
  SET_ALL_SHEETS = 'SET_ALL_SHEETS',
}

interface SheetUser {}

export interface GetSheet {
  id: string;
  name: string;
  code: string;
  recurrence: string;
  type: string;
  objective: string;
  startdate?: string;
  productionDay?: number;
  productionMonth?: number;
  nbDayNotificationDelayToProvide?: number;
  nbDayNotificationDelayToApprove?: number;
  nbDayNotificationDelayToValidate?: number;
  gedUrl: string;
  suppliers: SheetUser[];
  approvers: SheetUser[];
  validators: SheetUser[];
  contractId: string;
  nbDayToCreate?: number;
  description: string;
  theme: string;
  createdAt: string;
  updatedAt: string;
}

export interface CreateSheet {
  code: string;
  name: string;
  recurrence: string;
  type: string;
  objective: string;
  startDate: string;
  nbDayToCreate: number;
  contractId: string;
  productionDay: number;
  productionMonth: number;
  nbDayNotificationDelayToProvide: number;
  nbDayNotificationDelayToApprove: number;
  nbDayNotificationDelayToValidate: number;
  gedUrl: string|null;
  description: string;
  theme: string;
  suppliers: string[];
  validators: string[];
  approvers: string[];
}

export interface CreateSheetUser {
  userId: string;
}

export enum SheetRecurrence {
  RECURRENCE_MONTHLY = 'monthly',
  RECURRENCE_BIANNUAL = 'biannual',
  RECURRENCE_YEARLY = 'yearly',
  RECURRENCE_QUARTERLY = 'quarterly',
  RECURRENCE_PUNCTUAL = 'punctual',
}

export enum SheetType {
  TYPE_VALUE = 'value',
  TYPE_FILE = 'file',
}

export interface GetDetailedSheet extends GetSheet {
  suppliers: UserDetailSheet[];
  validators: UserDetailSheet[];
  approvers: UserDetailSheet[];
}

interface UserDetailSheet {
  id: string;
  firstName: string;
  lastName: string;
}
