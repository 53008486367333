import { loadingSpinner, setAllRequests } from '../store/actions';
import { setMyRequests, setToProduceRequests, triggerFilters } from '../store/actions/userRequests';
import { store } from '../store';
import instanceRequest, { Request } from './Request';
import getPaginationInfo from './Utils/pagination';
import { GET_ALL_REQUESTS_URI, GET_MY_REQUESTS_URI, GET_TO_PRODUCE_REQUESTS_URI } from '../constants/routes';
import { IndicatorFilterInterface } from '../store/types/indicator.type';
import { DateService } from './DateService';
import { initialStateFilter } from '../store/reducers/user_requests_reducer';
import { saveActivePage } from '../store/actions/params';

class UserRequestsService {
  private axiosService: Request;

  private filterNamesConversion:
    | {
        indicator: string;
        recurrence: string;
        startDate: string;
        endDate: string;
      }
    | any = {
    indicator: 'indicatorCode',
    recurrence: 'recurrence',
    startDate: 'validationLimitDateStart',
    endDate: 'validationLimitDateEnd',
  };

  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }

  public getAllUserRequest(
    page?: number,
    filters: IndicatorFilterInterface = initialStateFilter,
    sortField = 'indicatorCode',
    sortDirection = 'desc',
    itemsPerPage?: number
  ) {
    const { user } = store.getState();
    const endPoint = GET_ALL_REQUESTS_URI.replace('__contract_id__', user.info.contractId);

    store.dispatch(loadingSpinner(true));
    store.dispatch(saveActivePage({ activePage: page }));

    this.axiosService
      .get(endPoint, {
        params: this.prepareFilters(filters, page, sortField, sortDirection, itemsPerPage),
      })
      .then((response) => {
        const pagination = getPaginationInfo(response.headers);
        store.dispatch(
          setAllRequests({
            items: response.data,
            pagination: { ...pagination },
          })
        );
        store.dispatch(loadingSpinner(false));
      });
  }

  public getMyIndicatorUserRequest(
    page?: number,
    filters: IndicatorFilterInterface = initialStateFilter,
    sortField = 'indicatorCode',
    sortDirection = 'desc',
    itemsPerPage?: number
  ) {
    const { user } = store.getState();
    const endPoint = GET_MY_REQUESTS_URI.replace('__contract_id__', user.info.contractId);

    store.dispatch(loadingSpinner(true));
    store.dispatch(saveActivePage({ activePage: page }));

    this.axiosService
      .get(endPoint, {
        params: this.prepareFilters(filters, page, sortField, sortDirection, itemsPerPage),
      })
      .then((response) => {
        const pagination = getPaginationInfo(response.headers);
        store.dispatch(
          setMyRequests({
            items: response.data,
            pagination: { ...pagination },
          })
        );
        store.dispatch(loadingSpinner(false));
      });
  }

  public getToProduceUserRequest(
    page?: number,
    filters: IndicatorFilterInterface = initialStateFilter,
    sortField = 'id',
    sortDirection = 'desc',
    itemsPerPage?: number
  ) {
    const { user } = store.getState();
    const endPoint = GET_TO_PRODUCE_REQUESTS_URI.replace('__contract_id__', user.info.contractId);

    store.dispatch(loadingSpinner(true));

    this.axiosService
      .get(endPoint, {
        params: this.prepareFilters(filters, page, sortField, sortDirection, itemsPerPage),
      })
      .then((response) => {
        const pagination = getPaginationInfo(response.headers);
        store.dispatch(
          setToProduceRequests({
            items: response.data,
            pagination: { ...pagination },
          })
        );
        store.dispatch(loadingSpinner(false));
      });
  }

  private prepareFilters(
    filters: IndicatorFilterInterface = initialStateFilter,
    page: number = 1,
    sortField = 'id',
    sortDirection = 'desc',
    itemsPerPage?: number
  ): [] {
    let queries: any = [];
    let filterFields: any = [];
    queries = {
      q: filters.q ?? '',
      currentPage: page,
      sortField,
      sortOrder: sortDirection,
      itemsPerPage: itemsPerPage,
    };

    if (filters.itemsPerPage) {
      queries = { ...queries, itemsPerPage };
    }

    if (filters.status) {
      queries = { ...queries, status: filters.status.value };
    }

    Object.entries(filters).map((filter: any) => {
      const filterName = filter[0];
      const filterValues = filter[1];
      if (null === filterValues || !this.filterNamesConversion[filterName]) {
        return null;
      }
      const filterNameConvert = this.filterNamesConversion[filterName];
      let value = filterValues.value;
      if (
        filterNameConvert === this.filterNamesConversion.startDate ||
        filterNameConvert === this.filterNamesConversion.endDate
      ) {
        value = DateService.dateToString(filterValues);
      }
      if (filterNameConvert === this.filterNamesConversion.recurrence) {
        value = filterValues;
      }
      filterFields[`filterFields[${filterNameConvert}]`] = value;
      queries = { ...queries, ...filterFields };
    });

    return queries;
  }
}

export default new UserRequestsService(instanceRequest);
