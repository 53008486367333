import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { GetSheet } from '../../../store/types/sheet.type';
import { DateService } from '../../../services/DateService';
import { Link } from 'react-router-dom';
import { SzIcon } from '@suezenv/react-theme-components';

export const getColumns = (t: (key: string) => string): ColumnDescription<GetSheet>[] => {
  return [
    {
      width: 100,
      classes: 'code',
      headerClasses: 'sz-icon-line',
      dataField: 'code',
      text: t('code'),
    },
    {
      width: 120,
      classes: 'name',
      headerClasses: 'sz-icon-line',
      dataField: 'name',
      text: t('name'),
    },
    {
      classes: 'recurrence',
      headerClasses: 'sz-icon-line',
      dataField: 'recurrence',
      text: t('recurrence'),
      formatter: (recurrence: string) => <>{t(recurrence)}</>,
    },
    {
      classes: 'start-date',
      headerClasses: 'sz-icon-line',
      dataField: 'startDate',
      text: t('startDate'),
      formatter: (date: string) => <>{DateService.formatDate(date)}</>,
    },
  ];
};
