import { INDICATOR_REQUEST_TYPE } from './indicator.type';

export enum PARAMS_ACTIONS {
  SAVE_ACTIVE_TAB = 'SAVE_ACTIVE_TAB',
  SAVE_ACTIVE_PAGE = 'SAVE_ACTIVE_PAGE',
}

export interface ParamsState {
  activeTab?: INDICATOR_REQUEST_TYPE;
  activePage?: number;
}
