import React, { useEffect, useState } from 'react';
import { SzTextArea, SzButton, SzModal } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import { INDICATOR_STATUS, IndicatorStatusType } from '../../../../store/types/indicator.type';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import FileUploader from '../../../../components/elements/file-uploader/file-uploader';

interface Props {
  onClose?: () => void;
  onValidate: (status: IndicatorStatusType, values: any, bag: any) => void;
  show: boolean;
}

export interface ReportToApproveType {
  report: File | null;
  files: any[];
  comment: string;
}

const ModalReportToApproveStatus: React.FC<Props> = (props) => {
  const [show, setShow] = useState(props.show);
  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const { t } = useTranslation('indicator');
  const trans = (key: string): string => {
    return t('page.modal.report_to_approve_status.' + key);
  };

  const initialValue: ReportToApproveType = {
    report: null,
    files: [],
    comment: '',
  };

  const schema = yup.object().shape<Partial<ReportToApproveType>>({
    report: yup.mixed().required(),
  });

  return (
    <SzModal
      className="modal-report-to-approve-status"
      handleClose={() => props.onClose?.()}
      hideCloseBtn
      centered
      title={trans('title')}
      size="lg"
      show={show}
    >
      <Formik
        initialValues={initialValue}
        onSubmit={(values, bag) => props.onValidate(INDICATOR_STATUS.TO_APPROVE, values, bag)}
        validateOnMount
        validationSchema={schema}
      >
        {({ values, handleChange, handleSubmit, isValid, isSubmitting, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div>
                <FileUploader
                  className="report-wrapper"
                  multiple={false}
                  canDeleteFile
                  onChange={(files) => setFieldValue('report', files[0])}
                  dragAndDropText={trans('drag_drop_report')}
                />

                <div className="mt-4">
                  <FileUploader
                      className="report-wrapper"
                      canDeleteFile
                      showCurrentFiles
                      dragAndDropText={trans('drag_drop_text')}
                      onChange={(files) => setFieldValue('files', files)}
                  />
                </div>

                <SzTextArea
                  className="mt-4"
                  name="comment"
                  value={values.comment}
                  onChange={handleChange}
                  placeholder={trans('form.comment_label')}
                />
              </div>

              <div className="d-flex justify-content-around">
                <SzButton
                  variant="secondary"
                  onClick={() => {
                    setShow(false);
                    props.onClose?.();
                  }}
                >
                  {trans('button_cancel')}
                </SzButton>
                <SzButton isDisabled={!isValid || isSubmitting} type="submit" variant="primary">
                  {trans('button_validate')}
                </SzButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SzModal>
  );
};

export default ModalReportToApproveStatus;
