declare global {
  interface Window {
    REACT_APP_ANALYTICS_KEY: string;
    REACT_APP_API_HOST: string;
    REACT_APP_SUPPORT_HOST: string;
    REACT_APP_USER_ADMIN: string;
    REACT_APP_URGENT_REQUEST_PHONE_NUMBER: string;
    REACT_APP_URGENT_REQUEST_OPENING_TIME: string;
  }
}

const url = new URL(window.location.href);

// force test env (use REACT_APP_API_TEST_HOST for api calls)
const testEnv = url.searchParams.get('test_env');

if (testEnv) {
  localStorage.setItem('test_env', testEnv);
}

const isTestEnv = localStorage.getItem('test_env');

let apiUrl = window.REACT_APP_API_HOST || process.env.REACT_APP_API_HOST;
const googleAnalyticsId = window.REACT_APP_ANALYTICS_KEY || process.env.REACT_APP_ANALYTICS_KEY;
const supportUrl = window.REACT_APP_SUPPORT_HOST || process.env.REACT_APP_SUPPORT_HOST;
const userAdminUrl = window.REACT_APP_USER_ADMIN || process.env.REACT_APP_USER_ADMIN;
const urgentRequestPhoneNumber =
  window.REACT_APP_URGENT_REQUEST_PHONE_NUMBER || process.env.REACT_APP_URGENT_REQUEST_PHONE_NUMBER;
const urgentRequestOpeningTime =
  window.REACT_APP_URGENT_REQUEST_OPENING_TIME || process.env.REACT_APP_URGENT_REQUEST_OPENING_TIME;

if (process.env.REACT_APP_API_TEST_HOST && isTestEnv && 1 === parseInt(isTestEnv)) {
  apiUrl = process.env.REACT_APP_API_TEST_HOST;
}

export const config = {
  apiUrl: apiUrl,
  googleAnalyticsId: googleAnalyticsId,
  supportUrl: supportUrl,
  userAdminUrl: userAdminUrl,
  urgentRequestPhoneNumber: urgentRequestPhoneNumber,
  urgentRequestOpeningTime: urgentRequestOpeningTime,
};
