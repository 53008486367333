import React from 'react';
import FreshdeskWidget from '@personare/react-freshdesk-widget';
import { useTranslation } from 'react-i18next';
import './freshdesk.scss';
import { config } from '../../config';

interface IFreshdeskProps {
  user: any;
}

const FreshdeskComponent = (props: IFreshdeskProps) => {
  const { user } = props;
  const { t } = useTranslation();
  const supportUrl = config.supportUrl;

  const firstName = user.info.firstName;
  const lastName = user.info.lastName;

  return (
    <FreshdeskWidget
      url={supportUrl}
      type="pop-up"
      formTitle={t('support_title')}
      submitThanks={t('support_thanks')}
      autofill={{
        requester: user.info.email,
        name: `${firstName} ${lastName}`,
      }}
    >
      <span className="sz-icon-line messages-bubble-question support-button" />
    </FreshdeskWidget>
  );
};

export default FreshdeskComponent;
