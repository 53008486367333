import { loadingSpinner, setAllSheets } from '../store/actions';
import { store } from '../store';
import instanceRequest, { Request } from './Request';
import getPaginationInfo from './Utils/pagination';
import { GET_ALL_SHEETS_URI } from '../constants/routes';

class SheetService {
  private axiosService: Request;

  public constructor(axiosService: Request) {
    this.axiosService = axiosService;
  }

  public getAllSheets(page?: number, itemsPerPage: number = 100) {
    const { user } = store.getState();
    store.dispatch(loadingSpinner(true));
    const url = GET_ALL_SHEETS_URI.replace('__contract_id__', user.info.contractId);
    const endPoint = `${url}?currentPage=${page}&itemsPerPage=${itemsPerPage}`;

    this.axiosService.get(endPoint).then((response) => {
      const pagination = getPaginationInfo(response.headers);
      store.dispatch(
        setAllSheets({
          items: response.data,
          pagination: { ...pagination },
        })
      );
      store.dispatch(loadingSpinner(false));
    });
  }
}

export default new SheetService(instanceRequest);
