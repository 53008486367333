import React from 'react';
import { connect } from 'react-redux';
import {
  INDICATOR_STATUS,
  IndicatorRequestType,
  IndicatorFilterInterface,
  IndicatorStatusType,
} from '../../../store/types/indicator.type';
import { useIndicatorReporting } from '../../../services/hooks/indicator-reporting.hook';
import ReportingItem from './reporting.item';
import { getReportingCountForType } from './service';
import { triggerFilters } from '../../../store/actions/userRequests';
import './reporting.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  type: IndicatorRequestType;
  filter: IndicatorFilterInterface;
  triggerFilters: (payload: any) => void;
}

const ReportingList: React.FC<Props> = ({ type, filter, triggerFilters }) => {
  const { t } = useTranslation('indicator');
  const handleOnClickStatus = (status: IndicatorStatusType) => {
    const indicatorFilter: any = { value: status, label: t(`reporting.${status}`) };
    triggerFilters({ ...filter, status: indicatorFilter });
  };

  let [indicators] = useIndicatorReporting(type, filter);
  const types = [
    INDICATOR_STATUS.DRAFT,
    INDICATOR_STATUS.REFUSED,
    INDICATOR_STATUS.TO_APPROVE,
    INDICATOR_STATUS.TO_VALIDATE,
    INDICATOR_STATUS.VALIDATED,
  ];

  return (
    <div className="d-flex list-reporting">
      {types.map((type) => (
        <ReportingItem
          key={type}
          status={type}
          count={getReportingCountForType(type, indicators)}
          onClick={handleOnClickStatus}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  filter: state.userRequests.filter,
});

const mapDispatchToProps = {
  triggerFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportingList);
