import React from 'react';
import { SzButton, SzIcon } from '@suezenv/react-theme-components';
import './filter.scss';

const SelectedFilter = (props: {
  name: string;
  label: string;
  deleteHandler: (propName: string, value: string) => void;
}) => {
  return (
    <SzButton
      className="d-md-inline-flex filter-tag mr-2"
      onClick={() => props.deleteHandler(props.name, props.label)}
      variant="secondary"
    >
      {props.label}
      <SzIcon icon="remove" variant="line" className="ml-2" />
    </SzButton>
  );
};

export default SelectedFilter;
