import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SzBox, SzButton } from '@suezenv/react-theme-components';
import TimelineHistoryItem from './indicator.timeline.history.item';

interface Props {
  items: any;
}

const TimelineHistory: React.FC<Props> = ({ items }) => {
  const { t } = useTranslation('indicator');
  const [showDetails, setShowDetails] = useState<boolean>(false);
  return (
    <>
      <SzBox className="d-flex pt-5 justify-content-end align-items-center" tag="div">
        <div className="d-flex">
          <SzButton
            icon={showDetails ? 'subtract-square' : 'navigation-menu-4'}
            variant="tertiary"
            onClick={() => {
              setShowDetails(!showDetails);
            }}
          >
            {showDetails ? t('timeline.hide_time_line_detail') : t('timeline.show_time_line_detail')}
          </SzButton>
        </div>
      </SzBox>
      {showDetails && items && (
        <SzBox className="d-flex flex-column entries" tag="div">
          {items.map((item: any, index: number) => (
            <TimelineHistoryItem item={item} index={index} />
          ))}
        </SzBox>
      )}
    </>
  );
};

export default TimelineHistory;
