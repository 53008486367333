import React, { useEffect, useState } from 'react';
import { SzTextArea, SzButton, SzModal } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
import { INDICATOR_STATUS, IndicatorStatusType } from '../../../../store/types/indicator.type';
import { Form, Formik } from 'formik';
import * as yup from 'yup';

interface Props {
  onClose?: () => void;
  onValidate: (status: IndicatorStatusType, values: any, bag: any) => void;
  show: boolean;
}

export interface ValueRefusedType {
  comment: string;
}

const ModalRefusedStatus: React.FC<Props> = (props) => {
  const [show, setShow] = useState(props.show);
  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const { t } = useTranslation('indicator');
  const trans = (key: string): string => {
    return t('page.modal.refused_status.' + key);
  };

  const initialValue: ValueRefusedType = {
    comment: '',
  };

  const schema = yup.object().shape<Partial<ValueRefusedType>>({
    comment: yup.string().required(),
  });

  return (
    <SzModal
      className="modal-value-to-approve-status"
      handleClose={() => props.onClose?.()}
      hideCloseBtn
      centered
      title={trans('title')}
      size="lg"
      show={show}
    >
      <Formik
        initialValues={initialValue}
        onSubmit={(values, bag) => props.onValidate(INDICATOR_STATUS.REFUSED, values, bag)}
        validateOnMount
        validationSchema={schema}
      >
        {({ values, handleChange, handleSubmit, isValid }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div>
                <SzTextArea
                  className="mt-2"
                  name="comment"
                  value={values.comment}
                  onChange={handleChange}
                  label={trans('form.comment_label')}
                  placeholder={trans('form.comment_placeholder')}
                />
              </div>

              <div className="d-flex justify-content-around">
                <SzButton
                  variant="secondary"
                  onClick={() => {
                    setShow(false);
                    props.onClose?.();
                  }}
                >
                  {trans('button_cancel')}
                </SzButton>
                <SzButton isDisabled={!isValid} type="submit" variant="primary">
                  {trans('button_validate')}
                </SzButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </SzModal>
  );
};

export default ModalRefusedStatus;
