import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { SzTypographie } from '@suezenv/react-theme-components';
import { Table } from '../../components/elements/table';
import { formatRequests } from '../../helper/format-data.helper.tsx';
import useTableHelper from '../../helper/table.helper.tsx';
import { setRequestData } from '../../helper/user-request-data.helper';
import { IUserInfo } from '../../store/types';
import { IndicatorFilterInterface } from '../../store/types/indicator.type';
import { UserRequestType } from '../../store/types/user-request.type';
import Pagination, { getPaginationData } from '../pagination';
import "./userRequests.list.scss";
import { useHistory } from 'react-router-dom';

interface IUserRequestList {
  type: UserRequestType;
  userRequests: any;
  user: IUserInfo;
  activePage: number;
  storedFilter: IndicatorFilterInterface;
}

const UserRequestsList: FC<IUserRequestList> = (props: IUserRequestList) => {
  const { t } = useTranslation();
  const history = useHistory();
  const baseSort = {direction: 'asc', field: 'indicatorCode'};

  const [columns] = useTableHelper(props.type, props.userRequests, baseSort, props.storedFilter);
  const [data, setData] = useState();

  const rowClassNameFormat = (row: any) => {
    return `request-row-${row.statusId.replace('_', '-')}`;
  };

  const paginationData = getPaginationData(props.userRequests[props.type]);

  useEffect(() => {
    setRequestData(props.type, props.activePage, props.storedFilter);
  }, [props.storedFilter]);

  useEffect(() => {
    setData(formatRequests(props.userRequests[props.type], t));
  }, [props.userRequests[props.type]]);

  const rowEvents = {
    onClick: (event: any, indicator: any) => history.push(`/indicateurs/${indicator.id}`),
  }

  return (
    <>
      <div className="row ">
        <div className="col-8">
          <SzTypographie variant="text" className="list-result">
            {t('number_request_on_all', {
              number_show: props.userRequests[props.type].items.length,
              number_all: paginationData['x-pagination-total-count']
                ? paginationData['x-pagination-total-count']
                : props.userRequests[props.type].items.length,
            })}
          </SzTypographie>
        </div>
      </div>
      <div className="row mt-3">
        {columns.length && (
          <div className="col list-table">
            <Table rowEvents={rowEvents} tableClasses="userRequests-list-table" rowClasses={rowClassNameFormat} columns={columns} items={data} />
            <div className="row">
              <div className="col offset-5">
                <Pagination type={props.type} onPageHandle={setRequestData} data={props.userRequests[props.type]} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userRequests: state.userRequests,
  storedFilter: state.userRequests.filter,
  user: state.user,
  activePage: state.params.activePage,
});

export default connect(mapStateToProps)(UserRequestsList);
