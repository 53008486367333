import React from 'react';
import { SzButton } from '@suezenv/react-theme-components';
import { useTranslation } from 'react-i18next';
interface IError {
  title: string;
  description?: string;
  img?: string;
  refreshPage: () => void;
}
const ErrorComponent: React.SFC<any> = (props: IError) => {
  const { t } = useTranslation();
  const { description, title, img } = props;
  return (
    <div className="h-75 row  align-items-center justify-content-center">
      <div className="col-auto text-center">
        <h1 className="title display-1 text-center"> {title} </h1>
        {props.img && <img className="text-center mb-4" src={img} alt="" />}
        <h1 className="title text-center">
          {' '}
          {description ? description : t('ERROR_MESSAGE')} <div>{t('CONTACT_ADMIN_LABEL')}</div>
        </h1>
        <div className="row align-items-center justify-content-center mt-4">
          <div className="col-5 ">
            <SzButton onClick={props.refreshPage}>{t('REFRESH_LABEL')}</SzButton>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ErrorComponent;
