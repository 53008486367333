import { useEffect } from 'react';
import { AuthenticateService } from '../../services/AuthenticateService';

const Logout = () => {
  useEffect(() => {
    const token = localStorage.getItem('token-atlas-indicateur');
    if (token) {
      AuthenticateService.logout();
    }
  }, []);

  return null;
};

export default Logout;
