import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { SzBox, SzTypographie } from '@suezenv/react-theme-components';
import { SzTimeLine } from '../../../../components/elements/SzTimeLine';
import { Status } from '../../../../constants';
import { DateService } from '../../../../services/DateService';
import { useIndicatorStatus } from '../../../../services/hooks/indicator-status.hook';
import { GetOneIndicator } from '../../../../store/types/one-indicator.type';
import TimelineHistory from './indicator.timeline.history';
import './indicator.timeline.scss';

const NUMBER_CHAR_TO_SHOW_TIME_LINE = 10;
const TIME_LINE_STATUS = 'status';
const TIME_LINE_COMMENT = 'comment';
const ASTEO_FIRST_NAME = 'ASTEO';

interface Props {
  indicator: GetOneIndicator;
}

const IndicatorTimeline: React.FC<Props> = ({ indicator }) => {
  const { t } = useTranslation('indicator');
  const [status] = useIndicatorStatus(indicator.id);
  const [items, setItems] = useState([]);

  const sortById = (firstItem: any, secondItem: any) => {
    return firstItem.id >= secondItem.id ? 1 : -1;
  };
  const sortBydate = (firstDate: any, secondDate: any) => {
    return DateService.stringToDate(firstDate.createdAt).getTime() >
      DateService.stringToDate(secondDate.createdAt).getTime()
      ? 1
      : -1;
  };
  const truncateString = (text: string) => {
    if (!text || text.length <= NUMBER_CHAR_TO_SHOW_TIME_LINE) {
      return text;
    }
    return text.slice(0, NUMBER_CHAR_TO_SHOW_TIME_LINE) + '...';
  };

  const getStatusItems = (statusList: any) => {
    let items: any = [];
    statusList.sort(sortBydate);

    for (let statusItem of statusList) {
      const isFirstItem = items.length === 0;
      const { id, status, createdAt, comment, applicationUser } = statusItem;
      const { firstName, lastName } = applicationUser;
      const previousItem = items.slice(-1);
      const previousStatus = previousItem[0] ? previousItem[0].status : '';

      if (previousStatus === status.id && !comment) {
        continue;
      } // Retro compatible

      const isComment = previousStatus === status.id && comment;

      const itemClassName = isComment ? 'timeline-comment' : `timeline-${status.id.replace('_', '-')}`;
      const commentTextTimeline = t('timeline.timeline_comment_text', { comment: truncateString(comment) });
      const textTimeline = isFirstItem ? t('timeline.start_item_text') : isComment ? commentTextTimeline : t(`reporting.${status.id}`);
      const detailComment = t('timeline.detail_timeline_comment', {
        name: `${firstName ? firstName : ''} ${lastName ? lastName : ''} `,
      });

      let detailStatus = t('timeline.detail_timeline_status', {
        status: t(`timeline.status.${status.id}`),
        firstName: applicationUser.firstName,
        lastName: applicationUser.lastName,
      });

      // close auto
      if (
        status.id === Status.STATUS_VALIDATED &&
        applicationUser.firstName === ASTEO_FIRST_NAME &&
        applicationUser.lastName === ''
      ) {
        detailStatus = t('timeline.auto_close_by', {
          firstName: applicationUser.firstName,
        });
      }

      const detail = isComment ? detailComment : detailStatus;

      const firstItemCreatedAt = moment(createdAt).add(1, 's').format('YYYY-MM-DDTHH:mm:ssZ').toString();

      items.push({
        className: isFirstItem ? 'timeline-start' : itemClassName,
        status: status.id,
        id: id,
        detail: detail,
        text: textTimeline,
        createdAt: isFirstItem ? firstItemCreatedAt : createdAt,
        rawComment: comment,
        type: isComment ? TIME_LINE_COMMENT : TIME_LINE_STATUS,
        showInTimeline: !isComment,
      });
    }

    return items;
  };

  const getDateLimitStatusItems = () => {
    const items = [];
    const dateTimes = {
      to_produce: indicator.data.toProducedLimitDate,
      to_approve: indicator.data.toApproveLimitDate,
      to_validate: indicator.data.toValidateLimitDate,
      production: indicator.data.validationLimitDate,
    };
    for (const [key, value] of Object.entries(dateTimes)) {
      const formatedDate = moment(value).add(1, 's').format('YYYY-MM-DDTHH:mm:ssZ').toString();
      let item = {
        className: 'timeline-info',
        status: key,
        id: key,
        detail: t(`timeline.limit_${key}_date_text`),
        text: t(`timeline.limit_${key}_date_text`),
        createdAt: formatedDate,
        rawComment: null,
        type: TIME_LINE_STATUS,
        showInTimeline: true,
      };
      items.push(item);
    }

    return items;
  };

  const formattedItems = (statusList: any) => {
    let items: any = [];
    if (statusList.length === 0) {
      return items;
    }

    const itemsStatus = getStatusItems(statusList);
    const itemsDateLimitStatus = getDateLimitStatusItems();

    items = [...itemsStatus, ...itemsDateLimitStatus];

    items.sort(sortBydate);

    let allItems: any[] = [];

    // if validation step is shown, don t show anything else.
    let validationDateShown = false;

    for (let item of items) {
      if (validationDateShown) {
        break;
      }

      if (item.status === Status.STATUS_VALIDATED) {
        validationDateShown = true;
      }

      allItems.push(item);
    }

    // check  if last item is close status
    const lastItem = allItems[allItems.length - 1];

    if (lastItem.status === Status.STATUS_VALIDATED) {
      lastItem.className = 'timeline-end';
      lastItem.showInTimeline = true;
    }

    return allItems;
  };

  useEffect(() => {
    const items = formattedItems(status.sort(sortById));
    setItems(items);
  }, [status]);

  return (
    <>
      {items && (
        <SzBox className="d-flex detail-block3 pb-1 flex-column infobox" tag="div">
          <SzBox className="d-flex" tag="span">
            <SzTypographie variant="text" weight="bold">
              {t('page.time_line_title')}
            </SzTypographie>
          </SzBox>
          <SzTimeLine items={items} />
          <TimelineHistory items={items} />
        </SzBox>
      )}
    </>
  );
};

export default IndicatorTimeline;
