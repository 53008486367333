import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { SzTypographie } from '@suezenv/react-theme-components';
import { IndicatorStatusType } from '../../../store/types/indicator.type';

interface Props {
  status: IndicatorStatusType;
  count: number;
  onClick?: (status: IndicatorStatusType) => void;
}

const ReportingItem: React.FC<Props> = ({ status, count, onClick }) => {
  const { t } = useTranslation('indicator');

  const onClickOnStatus = 0 === count ? undefined : onClick;

  const classesControl = classnames('box-shadow status-indicator d-flex', {
    disabled: 0 === count,
  });

  return (
    <div className={classesControl} onClick={() => onClickOnStatus?.(status)}>
      <SzTypographie variant="text" className="m-0">
        <span className={`status-sticker status-sticker-${status}`} />
        <strong>{count}</strong> {t(`reporting.${status}`)}
      </SzTypographie>
    </div>
  );
};

export default ReportingItem;
